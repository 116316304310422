import { Avatar, List, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import lodash from 'lodash'
import React, { ReactNode } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import EmptyData from '../../../Component/EmptyData'
import { Col, Divider, Row } from 'antd'
import ProviderInterface from '../../../Interface/ProviderInterface'
import ShipmentInterface, { ServiceInterface } from '../../../Interface/ShipmentInterface'
import StatusInterface from '../../../Interface/StatusInterface'
import useWindowDimensions from '../../../Util/dimention'
import { formatDateTime1, formatMoneyByUnit } from '../../../Util/string'
import IMAGE_DEFAULT from '../../../resources/images/empty-image.jpg'
import { trans } from '../../../resources/localization/Localization'
import SkeletonOrders from '../Skeleton'
import HeaderList from './HeaderList'
import { TooltipCommon } from '../../../Component/basic/TooltipCommon'
import { SHIPMENT_VIEW_MIDMILE_PARTNER } from '../../../Config/permission'
import SecurityService from '../../../Util/SecurityService'
import { MidmileView } from '../../../Component/views/MidmileView'
interface ListProps {
  total: number
  data: Array<ShipmentInterface>
  providers: Array<ProviderInterface>
  statuses?: Array<StatusInterface>
  typeDataTable: string
  handleTypeTableData: (val: string) => void
  loading?: boolean
  isSkeleton?: boolean
  renderProviderLink: (provider: string, code: string) => ReactNode
}
const ShipmentList: React.FC<ListProps> = (props) => {
  const { width } = useWindowDimensions()
  const {
    data,
    typeDataTable,
    providers,
    statuses,
    total,
    renderProviderLink,
    loading,
    isSkeleton,
    handleTypeTableData,
  } = props
  const navigate = useNavigate()

  return (
    <>
      {!isSkeleton && (
        <List
          className="shipments_items pd-16"
          pagination={false}
          header={
            <HeaderList
              total={total}
              typeDataTable={typeDataTable}
              setTypeDataTable={(val: string) => handleTypeTableData(val)}
            />
          }
          dataSource={data}
          locale={{ emptyText: <EmptyData text={trans('orders.empty')} /> }}
          renderItem={(item: ShipmentInterface, idx) => {
            const statusObj = statuses?.find(({ code }: StatusInterface) => code === item.status)
            const provider = providers.find((prod: ProviderInterface) => prod.code === item.provider)

            const services = lodash.get(item, 'services', [])
            // return width > 1280 ? (
            return true ? (
              <List.Item
                key={item.code}
                onClick={() => navigate(`/shipments/${item.code}`)}
                className={`cursor-pointer ${idx % 2 === 0 ? 'row-dark' : 'row-light'}`}
                style={{ minWidth: 1080 }}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item.image ? item?.image : IMAGE_DEFAULT}
                      shape="square"
                      size={80}
                      className="bd-none-impt rad-6"
                    />
                  }
                  title={
                    <Row align="middle" gutter={[32, 0]}>
                      <Col span={8}>
                        <Row align="middle" justify="space-between">
                          <div className="flex">
                            <Tooltip color="#CD5E77" title={<span className="">{trans('shipments.code')}</span>}>
                              <span className="text-500 txt-color-view">{`#${item.code}`}</span>
                            </Tooltip>
                            <Paragraph
                              className="robotomedium mg-0 txt-color-view fsz-14 line-h-22 whitespace"
                              copyable={{
                                text: item.code,
                                icon: [
                                  <Tooltip key={'asasas'} title={<span className="">Copy</span>} color="#CD5E77">
                                    <i className="far fa-copy line-h-14"></i>
                                  </Tooltip>,
                                  <Tooltip key={'asasasd'} title={<span className="">Copied</span>} color="#CD5E77">
                                    <i className="far fa-check fsz-12 line-h-14"></i>
                                  </Tooltip>,
                                ],
                                tooltips: [false, false],
                              }}
                            ></Paragraph>
                          </div>

                          {renderProviderLink(item.provider, item.providerShipmentCode)}
                        </Row>

                        <Row align="middle" justify="space-between">
                          <Tooltip color="#CD5E77" title={<span className="">Nhà cung cấp | Người bán</span>}>
                            <div className={'dpl-flex align-items-center fsz-14 line-h-22 mg-t-6'}>
                              <Avatar size={20} src={provider?.logo} shape={'circle'} className="bd-none-impt mg-r-4" />
                              <span className="txt-color-black fsz-14 line-h-22">{provider?.name}</span>
                              <Divider type={'vertical'} className={'mx-10 bd-color-black h-16 line-h-22 mx-4'} />
                              <span className={'robotoregular fsz-14 line-h-22'}>{item.providerUsername}</span>
                            </div>
                          </Tooltip>

                          <span className="flex align-items-center " style={{ marginLeft: 8 }}>
                            <Tooltip color="#CD5E77" title={<span className="">Đặt / Nhận</span>}>
                              <span className="mg-r-3 fsz-14 line-h-22 mg-l-16">{`${
                                item.orderedQuantity ? item.orderedQuantity : '---'
                              } / ${item.receivedQuantity ? item.receivedQuantity : '---'}`}</span>
                            </Tooltip>
                          </span>
                        </Row>

                        <Row align="middle" justify="space-between" className="mg-t-6">
                          <span className="fsz-14 line-h-22">
                            <Tooltip
                              color="#CD5E77"
                              title={
                                <span className="">
                                  {trans('shipments.createAtTooltip')}
                                  {/* Thời gian tạo */}
                                </span>
                              }
                            >
                              <span className="font-size-12 line-h-22 txt-color-gray">
                                {item.timestamp ? formatDateTime1(item.timestamp) : '---'}{' '}
                              </span>
                            </Tooltip>
                          </span>

                          {SecurityService.can(SHIPMENT_VIEW_MIDMILE_PARTNER) && (
                            <TooltipCommon title="Vận chuyển Midmile" color="#CD5E77">
                              {item?.midMileView?.[0]?.code ? (
                                <span>
                                  <MidmileView midMileView={item.midMileView} />
                                </span>
                              ) : (
                                '---'
                              )}
                            </TooltipCommon>
                          )}
                        </Row>
                      </Col>

                      <Col span={12}>
                        <Row align="middle" className="">
                          {item.services.length > 0 && (
                            <div className="mg-r-18 flex flex-wrap mg-t-6">
                              <span className="txt-color-gray fsz-14 line-h-20 whitespace mg-r-4">
                                {`${trans('shipments.services')}:`}
                              </span>
                              {services.length > 0
                                ? services.map((item: ServiceInterface, idx: number) => {
                                    let colorService = ''
                                    if (item.needApprove) {
                                      if (item.approved === null || item.approved === undefined) {
                                        colorService = 'services-pending'
                                      } else if (item.approved === false) {
                                        colorService = 'services-reject'
                                      }
                                    }
                                    return (
                                      <span className="items-center text-500" key={idx}>
                                        <span
                                          className={`mg-r-4 text text-reg   ${colorService}`}
                                        >{`${item.name}`}</span>
                                        {idx !== services.length - 1 && <span className="mg-r-4">|</span>}
                                      </span>
                                    )
                                  })
                                : '---'}
                            </div>
                          )}
                        </Row>

                        <span className="flex mg-t-6 gap-24">
                          <div className="grid flex-basis-40pc">
                            <div>
                              <span className="mg-r-4 txt-color-gray">{trans('shipments.customer')}:</span>
                              <span className={`${item.username ? 'text-500' : ''}`}>
                                {' '}
                                {item.username ? item.username : '---'}
                              </span>
                            </div>
                            <div className="mg-t-6">
                              <span className="mg-r-4 txt-color-gray">
                                {/* {trans('shipments.needPaid')}: */}
                                Mã đơn hàng khách hàng:
                              </span>
                              <span className={`txt-color-black ${item.refShipmentCode ? 'text-500' : ''}`}>
                                {item.refShipmentCode ? item.refShipmentCode : '---'}
                              </span>
                            </div>
                          </div>

                          <div className="grid ">
                            <div>
                              <span className="mg-r-4 txt-color-gray">{trans('shipments.chargedWeight')}:</span>
                              <span className={`${item.actualWeight ? 'text-500' : ''}`}>
                                {item.actualWeight ? `${item.actualWeight} kg` : '---'}
                              </span>
                            </div>
                            <div className="mg-t-6 ">
                              <span className="mg-r-4 txt-color-gray">{trans('shipments.needPaid')}:</span>
                              <span className={`txt-color-red ${item.totalUnpaid ? 'text-500' : ''}`}>
                                {item.totalUnpaid ? (
                                  formatMoneyByUnit(item.totalUnpaid)
                                ) : (
                                  <span className="txt-color-black">---</span>
                                )}
                              </span>
                            </div>
                          </div>
                        </span>
                      </Col>

                      <Col span={4}>
                        <div className="shipment-status flr">
                          <span
                            className="line-h-22 rad-25 txt-color-white px-16 text-500 py-4 txt-capitalize font-size-14  robotomedium "
                            style={{ backgroundColor: `${statusObj?.color}` }}
                          >
                            {statusObj?.name}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  }
                />
              </List.Item>
            ) : width > 767 ? (
              <>
                <List.Item
                  key={item.code}
                  onClick={() => navigate(`/shipments/${item.code}`)}
                  className={`cursor-pointer`}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={item.image ? item?.image : ''}
                        shape="square"
                        size={56}
                        className="bd-none-impt rad-6"
                      />
                    }
                    title={
                      <Row align="middle" gutter={[16, 16]}>
                        <Col span={8}>
                          <Row align="middle" justify="space-between">
                            <Link to={`/shipments/${item.code}`}>
                              <Tooltip color="#CD5E77" title={<span className="">{trans('shipments.code')}</span>}>
                                <span className="text-500">{`#${item.code}`}</span>
                              </Tooltip>
                            </Link>
                            <Paragraph
                              className="robotomedium mg-0 txt-color-view fsz-14 line-h-22 whitespace"
                              copyable={{
                                text: item.code,
                                icon: [
                                  <Tooltip key={'sadsadas'} title={<span className="">Copy</span>} color="#CD5E77">
                                    <i className="far fa-copy line-h-14 "></i>
                                  </Tooltip>,
                                  <Tooltip key={'sadsasada'} title={<span className="">Copied</span>} color="#CD5E77">
                                    <i className="far fa-check fsz-12 line-h-14 "></i>
                                  </Tooltip>,
                                ],
                                tooltips: [false, false],
                              }}
                            ></Paragraph>
                            {renderProviderLink(item.provider, item.providerShipmentCode)}
                          </Row>

                          <Row align="middle" justify="space-between">
                            <Tooltip color="#CD5E77" title={<span className="">Người bán</span>}>
                              <div className={'dpl-flex align-items-center fsz-14 line-h-22'}>
                                <Avatar
                                  size={20}
                                  src={provider?.logo}
                                  shape={'circle'}
                                  className="bd-none-impt mg-r-4"
                                />
                                <span className="txt-color-black fsz-14 line-h-22">{provider?.name}</span>
                                <Divider type={'vertical'} className={'mx-10 bd-color-black h-16 line-h-22 mx-4'} />
                                <span className={'robotoregular fsz-14 line-h-22'}>{item.providerUsername}</span>
                              </div>
                            </Tooltip>

                            <span className="flex align-items-center">
                              <Tooltip
                                color="#CD5E77"
                                title={<span className="">{trans('shipments.qtyTooltip')}</span>}
                              >
                                <span className="mg-r-3 fsz-14 line-h-22 mg-l-16">{`${
                                  item.orderedQuantity ? item.orderedQuantity : '---'
                                } / ${item.receivedQuantity ? item.receivedQuantity : '---'}`}</span>
                              </Tooltip>
                            </span>
                          </Row>
                        </Col>

                        <Col span={12}>
                          <span className="flex">
                            <span className="grid mg-r-20">
                              <div>
                                <span className="mg-r-4 txt-color-gray">{trans('shipments.customer')}:</span>
                                <span className={`${item.username ? 'text-500' : ''}`}>
                                  {' '}
                                  {item.username ? item.username : '---'}
                                </span>
                              </div>
                              <div>
                                <span className="mg-r-4 txt-color-gray">
                                  {/* {trans('shipments.needPaid')}: */}
                                  Mã đơn hàng khách hàng:
                                </span>
                                <span className={`${item.refShipmentCode ? 'text-500' : ''}`}>
                                  {item.refShipmentCode ? item.refShipmentCode : '---'}
                                </span>
                              </div>
                            </span>

                            <span className="grid">
                              <div>
                                <span className="mg-r-4 txt-color-gray">{trans('shipments.chargedWeight')}:</span>
                                <span className={`${item.actualWeight ? 'text-500' : ''}`}>
                                  {' '}
                                  {item.actualWeight ? `${item.actualWeight} kg` : '---'}
                                </span>
                              </div>
                              <div>
                                <span className="mg-r-4 txt-color-gray">{trans('shipments.needPaid')}:</span>
                                <span className={`${item.totalUnpaid ? 'text-500 txt-color-red' : ''}`}>
                                  {item.totalUnpaid ? formatMoneyByUnit(item.totalUnpaid) : '---'}
                                </span>
                              </div>
                            </span>
                          </span>
                        </Col>
                        <Col span={4} className="grid grid-items-end ">
                          <Row>
                            <div className="shipment-status mg-bt-4 flex white-nowrap mg-t-6">
                              <span
                                className=" rad-25 txt-color-white px-10 py-5 txt-capitalize fsz-14  robotomedium text-500"
                                style={{
                                  backgroundColor: `${statusObj?.color}`,
                                }}
                              >
                                {statusObj?.name}
                              </span>
                            </div>
                          </Row>

                          <Row>
                            <span className="font-size-12 line-h-22 txt-color-gray mg-r-8">
                              {item.timestamp ? formatDateTime1(item.timestamp) : '---'}{' '}
                            </span>
                          </Row>
                        </Col>
                      </Row>
                    }
                  />
                </List.Item>
                <Row align="middle" className={`rad-8 ${width < 1024 ? '' : 'mg-l-20'} `}>
                  {item.services.length > 0 && (
                    <div className="mg-r-18 flex white-nowrap  truncate-multiline  ">
                      <span className="txt-color-view font-size-14 line-h-20 whitespace mg-r-4 text-500">
                        {/* {`${t('shipments.services')}:`} */}
                        Dịch vụ:{' '}
                      </span>
                      {services.length > 0
                        ? services.map((item: ServiceInterface, idx: number) => {
                            let colorService = ''
                            if (item.needApprove) {
                              if (item.approved === null || item.approved === undefined) {
                                colorService = 'services-pending'
                              } else if (item.approved === false) {
                                colorService = 'services-reject'
                              }
                            }
                            return (
                              <span className="items-center " key={idx}>
                                <span
                                  className={`mg-r-4 text font-size-14 text-nowrap line-h-20 text-500 ${colorService}`}
                                >{`${item.name}`}</span>
                                {idx !== services.length - 1 && <span className="mg-r-4">|</span>}
                              </span>
                            )
                          })
                        : '---'}
                    </div>
                  )}
                </Row>
                <div className="end-row" />
              </>
            ) : (
              <List.Item
                key={item.code}
                onClick={() => navigate(`/shipments/${item.code}`)}
                className={`cursor-pointer ${idx % 2 === 0 ? 'row-dark' : 'row-light'}`}
              >
                <List.Item.Meta
                  title={
                    <>
                      <div className="flex justify-between">
                        <Col className="flex">
                          <Avatar
                            src={item.image ? item?.image : ''}
                            shape="square"
                            size={56}
                            className="bd-none-impt rad-6"
                          />
                          <div className="mg-l-10">
                            <Link to={`/shipments/${item.code}`}>
                              <Paragraph
                                className="robotomedium mg-0 txt-color-view fsz-14 line-h-22 whitespace"
                                copyable={{
                                  text: item.code,
                                  icon: [
                                    <Tooltip key={'asdas'} title={<span className="">Copy</span>} color="#CD5E77">
                                      <i className="far fa-copy  line-h-14 w-12"></i>
                                    </Tooltip>,
                                    <Tooltip key={'dvxvzxv'} title={<span className="">Copied</span>} color="#CD5E77">
                                      <i className="far fa-check fsz-12 line-h-14 w-12"></i>
                                    </Tooltip>,
                                  ],
                                  tooltips: [false, false],
                                }}
                              >
                                <span className="text-500">{`#${item.code}`}</span>
                              </Paragraph>
                            </Link>
                            <div className="fsz-14 line-h-22">{`${
                              item.orderedQuantity ? item.orderedQuantity : '---'
                            } / ${item.receivedQuantity ? item.receivedQuantity : '---'}`}</div>
                            {renderProviderLink(item.provider, item.providerShipmentCode)}
                          </div>
                        </Col>
                        <Col>
                          {/* <div className='shipment-status '> */}
                          <span
                            className="line-h-22 rad-25 txt-color-white px-10 py-5 txt-capitalize fsz-14  robotomedium "
                            style={{ backgroundColor: `${statusObj?.color}` }}
                          >
                            {statusObj?.name}
                          </span>
                          {/* </div> */}
                        </Col>
                      </div>
                      <div className="justify-start mg-t-12">
                        <span className="mg-r-4 txt-color-gray">{trans('shipments.needPaid')}:</span>
                        <span className="txt-color-red">
                          {item.totalUnpaid ? formatMoneyByUnit(item.totalUnpaid) : '---'}
                        </span>
                      </div>

                      <div className="justify-start mg-t-4">
                        <span className="mg-r-4 txt-color-gray">{trans('shipments.customer')}:</span>
                        <span className=""> {item.username ? item.username : '---'}</span>
                      </div>

                      <Row align="middle" className="mg-t-12 bg-color-pink px-10 py-10 rad-8">
                        {item.services.length > 0 && (
                          <div>
                            <span className="txt-color-view fsz-14 line-h-20 whitespace mg-r-4">
                              {/* {`${t('shipments.services')}:`} */}
                              Dịch vụ:{' '}
                            </span>
                            {services.length > 0
                              ? services.map((item: ServiceInterface, idx: number) => {
                                  let colorService = ''
                                  if (item.needApprove) {
                                    if (item.approved === null || item.approved === undefined) {
                                      colorService = 'services-pending'
                                    } else if (item.approved === false) {
                                      colorService = 'services-reject'
                                    }
                                  }
                                  return (
                                    <span className="items-center" key={idx}>
                                      <span
                                        className={`mg-r-4 
																				// d-inline-flex
																				  ${colorService}`}
                                      >{`${item.name}`}</span>
                                      {idx !== services.length - 1 && <span className="mg-r-4">|</span>}
                                    </span>
                                  )
                                })
                              : '---'}
                          </div>
                        )}
                      </Row>

                      <div className="flex justify-between mg-t-12">
                        <Row align="middle">
                          <div className={'dpl-flex align-items-center fsz-14 line-h-22'}>
                            <Avatar size={20} src={provider?.logo} shape={'circle'} className="bd-none-impt mg-r-4" />
                            <span className="txt-color-black fsz-14 line-h-22">{provider?.code}</span>
                            <Divider type={'vertical'} className={'mx-10 bd-color-black h-16 line-h-22 mx-4'} />
                            <span className={'robotoregular fsz-14 line-h-22'}>{item.providerUsername}</span>
                          </div>
                        </Row>

                        <Row align="middle">
                          <span className="fsz-14 line-h-22">
                            <span className="font-size-12 line-h-22 txt-color-gray">
                              {item.timestamp ? formatDateTime1(item.timestamp) : '---'}{' '}
                            </span>
                          </span>
                        </Row>
                      </div>
                    </>
                  }
                />
              </List.Item>
            )
          }}
        />
      )}
      {(loading || isSkeleton) && <SkeletonOrders data={data} loading={isSkeleton || loading} />}
    </>
  )
}

export default ShipmentList

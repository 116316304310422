import { Avatar, notification, Select, Tooltip } from 'antd'
import confirm from 'antd/lib/modal/confirm'
import Paragraph from 'antd/lib/typography/Paragraph'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import { OrdersApi } from '../../Api/OrdersApi'
import ConnectionBar from '../../Component/ConnectionBar'
import StatusBar from '../../Component/StatusBar'
import {
  ORDER_VIEW_PROVIDER_DETAIL,
  PERMISSION_ORDER_LOG_VIEW,
  PERMISSION_ORDER_UPDATE_STATUS,
} from '../../Config/permission'
import OrderDetailInterface from '../../Interface/OrderDetailInterface'
import ProviderInterface from '../../Interface/ProviderInterface'
import StatusInterface from '../../Interface/StatusInterface'
import { trans } from '../../resources/localization/Localization'
import SecurityService from '../../Util/SecurityService'
import { formatMoneyByUnit } from '../../Util/string'
import Logs from './Log'
import { GetOrderLinkType } from '../../hooks/fetch/useProvider'

const { Option } = Select

interface HeadingProps {
  statuses: StatusInterface[]
  details?: OrderDetailInterface
  providers: ProviderInterface[]
  currencyOfProvider: string
  loading: boolean
  getDetails: () => void
  getOrderLink: GetOrderLinkType
}
const Heading: React.FC<HeadingProps> = (props) => {
  const { statuses, details, providers, getDetails, getOrderLink, currencyOfProvider, loading } = props
  const { code } = useParams()
  const [provider, setProvider] = useState<ProviderInterface>()
  const [statusObj, setStatusObj] = useState<StatusInterface>()
  const [canUpdateStatus, setCanUpdateStatus] = useState(false)
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false)
  const [visibleLogModal, setVisibleLogModal] = useState(false)
  const [metadata, setMetadata] = useState<any>()

  useEffect(() => {
    const provider = providers?.find(({ code }: ProviderInterface) => code === details?.provider)
    const statusObj = statuses?.find(({ code }: StatusInterface) => code === details?.status)
    setCanUpdateStatus(details?.status === 'WAIT_TO_CONFIRM' && SecurityService.can(PERMISSION_ORDER_UPDATE_STATUS))
    setStatusObj(statusObj)
    setProvider(provider)
  }, [details?.provider, details?.status, providers, statuses])

  const handleChangeStatus = async (val: string) => {
    setLoadingChangeStatus(true)
    confirm({
      title: <span className="text-reg text-500">{trans('order.updateStatusConfirmTitle')}</span>,
      content: (
        <div className="flex items-center text text-reg flex-nowrap">
          <span className="text-reg white-nowrap mg-r-6">{trans('order.updateStatusConfirmContent')}</span>
          <span className="text-reg text-violet text-500 text-nowrap mg-r-6">
            {lodash.get(
              lodash.find(statuses, (stt: StatusInterface) => stt.code === details?.status),
              'name',
              '---'
            )}
          </span>
          <span className="text-reg text-nowrap mg-r-6">{trans('order.confirmTo')}</span>
          <span className="mg-l-6 text-reg text-violet text-500 text-nowrap">
            {lodash.get(
              lodash.find(statuses, (stt: StatusInterface) => stt.code === val),
              'name',
              '---'
            )}
          </span>
        </div>
      ),
      closable: true,
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
      className: 'confirm-status',
      okText: (
        <>
          {!loadingChangeStatus && <i className="fa-solid fa-check mg-r-10 text-reg" />}
          <span className="text-reg">{trans('order.confirmAccept')}</span>
        </>
      ),
      cancelText: (
        <>
          <i className="fa-solid fa-xmark text-reg mg-r-10" />
          <span className="text-reg">{trans('order.confirmCancel')}</span>
        </>
      ),
      onOk: async () => {
        setLoadingChangeStatus(true)
        try {
          await OrdersApi.updateStatus(code!, val)
          notification.success({ message: 'Thành công!' })
          setLoadingChangeStatus(false)
          getDetails()
        } catch (err) {
          setLoadingChangeStatus(false)
        }
      },
      maskClosable: true,
      centered: true,
    })
  }

  return (
    <div className="order-heading width100pc">
      {!loading ? (
        <Avatar
          shape="square"
          className="mg-r-8 order-heading__basic-image flex-shrink-none"
          src={lodash.get(details, 'image', '')}
        />
      ) : (
        <Skeleton width={56} height={56} className="mg-r-8" />
      )}
      <div className="flex flex-wrap items-center">
        <div className="flex order-heading__basic">
          <div className="flex flex-col justify-between order-heading__basic-text">
            <div className={`order-heading__basic-text--code flex items-center`}>
              {!loading ? (
                <Paragraph
                  copyable={{
                    text: lodash.get(details, 'providerOrderCode', '---'),
                    icon: [
                      <Tooltip title="Copy" color="#CD5E77">
                        <i className="far fa-copy line-h-14 w-14"></i>
                      </Tooltip>,
                      <Tooltip title="Copied" color="#CD5E77">
                        <i className="far fa-check fsz-12 line-h-14 w-14"></i>
                      </Tooltip>,
                    ],
                    tooltips: [false, false],
                  }}
                  className="mg-bt-0 mg-r-23 text-violet text-md text-500 line-h-28 text-nowrap"
                >
                  {`#${lodash.get(details, 'providerOrderCode', '---')}`}
                </Paragraph>
              ) : (
                <Skeleton width={90} height={28} className="mg-r-4" />
              )}

              {!canUpdateStatus &&
                (!loading ? (
                  <StatusBar statuses={statuses} colorDefault="#FFA634" status={lodash.get(details, 'status')} />
                ) : (
                  <Skeleton width={110} height={28} />
                ))}
              {canUpdateStatus &&
                (!loading ? (
                  <Select
                    value={details?.status}
                    onChange={handleChangeStatus}
                    size="middle"
                    className={`'order-heading__basic-text--code_status' ${
                      statusObj?.color ? statusObj?.color : 'status-bg--orange'
                    }`}
                    dropdownClassName="order-dropdown__status"
                    showArrow={canUpdateStatus}
                    suffixIcon={<i className="fa-solid fa-angle-down txt-color-white" />}
                    loading={loadingChangeStatus}
                  >
                    {statuses?.map((stt: StatusInterface) => (
                      <Option
                        key={stt.id}
                        value={stt.code}
                        disabled={stt.code !== 'DEPOSITED' && stt.code !== 'CANCELLED'}
                      >
                        {stt.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Skeleton width={110} height={28} />
                ))}
            </div>

            {!loading ? (
              <ConnectionBar
                logo={provider?.logo}
                username={details?.providerUsername}
                providerName={provider?.name}
                sizeImg={34}
                className="text-reg"
                divider="-"
                inFilter={false}
                isRedirect
              />
            ) : (
              <Skeleton width={205} height={26} />
            )}
          </div>
        </div>
        <div className="flex flex-col justify-between order-heading__basic-merchant">
          <div className="flex items-center order-heading__basic-merchant--cash">
            {!loading ? (
              <span className="label text-gray mg-r-3 line-h-28 text-nowrap">{`${trans('order.costTotal')}:`}</span>
            ) : (
              <Skeleton width={60} height={26} className="mg-r-3" />
            )}
            {!loading ? (
              <span className="value text-md text-700 line-h-28 text-nowrap">
                {formatMoneyByUnit(
                  lodash.identity(details?.provisionalAmount) ? details?.provisionalAmount : '---',
                  currencyOfProvider
                )}
              </span>
            ) : (
              <Skeleton width={80} height={26} />
            )}
          </div>
          <div className="flex items-center order-heading__basic-merchant--name">
            {!loading ? (
              <span className="label text-gray mg-r-3 line-h-34 text-nowrap">{`${trans('order.seller')}:`}</span>
            ) : (
              <Skeleton width={60} height={26} className="mg-r-3" />
            )}
            {!loading ? (
              <span className="value text-md text-700 line-h-34 text-nowrap">
                {lodash.identity(details?.merchantName) ? details?.merchantName : '---'}
              </span>
            ) : (
              <Skeleton width={80} height={26} />
            )}
          </div>
        </div>
        {SecurityService.can(PERMISSION_ORDER_LOG_VIEW) && (
          <>
            <div
              className="cursor-pointer shipment-heading-logs font-size-14 text-500 mg-l-20"
              onClick={() => {
                setVisibleLogModal(true)
                setMetadata({ page: 0, size: 15 })
              }}
            >
              {!loading ? (
                <i className="far fa-file-alt line-h-12 mg-r-4" />
              ) : (
                <Skeleton width={14} height={14} className="mg-r-4" />
              )}
              <span>
                {!loading ? (
                  //   trans("order.log")
                  'Log đơn hàng'
                ) : (
                  <Skeleton width={113} height={20} />
                )}
              </span>
            </div>

            {SecurityService.can(ORDER_VIEW_PROVIDER_DETAIL) && details && (
                <a
                  href={getOrderLink(details.provider, details?.providerOrderCode)}
                  style={{ whiteSpace: 'nowrap', marginLeft: 32 }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa-regular fa-arrow-up-right-from-square"></i> Đơn đối tác
                </a>
            )}

            <Logs
              visible={visibleLogModal}
              visibleHandler={(val: boolean) => setVisibleLogModal(val)}
              currency={currencyOfProvider}
              metadata={metadata}
              handleMetadata={(obj: any) => setMetadata(obj)}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Heading

import { Pagination, Table, Tooltip } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import EmptyData from "../../Component/EmptyData";
import SkeletonTable from "../../Component/SkeletonTable";
import CustomerCriteria from "../../Criteria/CustomerCriteria";
import { trans } from "../../resources/localization/Localization";
import { formatDateTime, getCleanFormFilter } from "../../Util/string";

interface ListProps {
  items: CustomerCriteria[];
  total: number;
  page: number;
  size: number;
  loading: boolean;
  handleFilter: (filter: CustomerCriteria) => void;
  setPage: (val: number) => void;
  setPageSize: (val: number) => void;
  filter: CustomerCriteria;
}
const List: React.FC<ListProps> = (props) => {
  const {
    items,
    total,
    page,
    size,
    setPage,
    setPageSize,
    handleFilter,
    filter,
    loading,
  } = props;

  const columns = [
    {
      title: trans("customer.code"),
      dataIndex: "code",
      width: 120,
      render: (code: string, record: any) => (
        <Paragraph
          className="text-violet text-reg mg-bt-0 text-500"
          copyable={{
            text: code,
            icon: [
              <Tooltip title={<span>Copy</span>} color="#CD5E77" key={'aaaa'}>
                <i className="far fa-copy line-h-14 w-14"></i>
              </Tooltip>,
              <Tooltip title={<span>Copied</span>} color="#CD5E77" key={'bbbb'}>
                <i className="far fa-check fsz-12 line-h-14 w-14"></i>
              </Tooltip>,
            ],
            tooltips: [false, false],
          }}
        >
          <Link to={`/customers/${record.username}`}>{code}</Link>
        </Paragraph>
      ),
    },
    {
      title: trans("customers.usernameInList"),
      dataIndex: "username",
      width: 158,
      render: (username: string, record: any) => (
        <span className="text-reg text-nowrap">{`${username} (${record.name})`}</span>
      ),
    },
    {
      title: trans("customer.phone"),
      dataIndex: "mobile",
      width: 120,
      render: (mobile: string) => (
        <span className="text-reg">{mobile ? mobile : "---"}</span>
      ),
    },
    {
      title: trans("customers.email"),
      dataIndex: "email",
      width: 208,
      render: (email: string) => <span className="text-reg">{email}</span>,
    },
    {
      title: trans("customers.createdAt"),
      dataIndex: "createdAt",
      width: 100,
      render: (createdAt: string) => (
        <span className="text-reg">
          {createdAt ? formatDateTime(moment(createdAt)) : "---"}
        </span>
      ),
    },
  ];

  const onChangePage = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
    handleFilter(
      getCleanFormFilter(
        {
          ...filter,
          page: (page - 1).toString(),
          size: pageSize.toString(),
        },
        [],
        ["pageSize"]
      )
    );
  };
  const showTotal = (total: number, range: number[]) =>
    `${range[0]} - ${range[1]} ${trans("orders.inRange")} ${total} ${trans(
      "orders.record"
    )}`;
  return (
    <>
      <div className="box-main mg-bt-24">
        <SkeletonTable
          columns={columns}
          loading={loading}
          className="main-table"
        >
          <Table
            dataSource={items}
            columns={columns}
            rowKey={(record: any) => record.id}
            className="main-table main-table__border-head-none"
            locale={{ emptyText: <EmptyData text={trans("orders.empty")} /> }}
            scroll={{ x: "max-content" }}
            title={() => (
              <div className="flex items-center gap-4">
                <span className="text-md text-500">
                  {trans("sidebar.customers")}
                </span>
                <span
                  className={`${
                    total > 0
                      ? "bg-primary text-white"
                      : "bg-color-gray-tab text-base"
                  } rad-20 px-8`}
                >
                  {total}
                </span>
              </div>
            )}
            pagination={false}
          />
        </SkeletonTable>
      </div>
      {total > 0 && (
        <Pagination
          total={total}
          showTotal={showTotal}
          pageSize={size}
          current={page}
          onChange={onChangePage}
          pageSizeOptions={["10", "20", "25", "50", "100"]}
          locale={{ items_per_page: `/ ${trans("orders.page")}` }}
          showSizeChanger={true}
          className="pagination-separate"
        />
      )}
    </>
  );
};

export default List;

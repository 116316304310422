import { Avatar, Table } from "antd";
import lodash from "lodash";
import React from "react";
import EmptyData from "../../../Component/EmptyData";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../Component/SkeletonTable";
import OrderItemInterface, {
  VariantPropertiesInterface,
} from "../../../Interface/OrderItemInterface";
import { trans } from "../../../resources/localization/Localization";
import { formatMoneyByUnit } from "../../../Util/string";
import { useState } from "react";
import IMAGE_DEFAULT from "../../../resources/images/empty-image.jpg";
import RowExtended from "./RowExtend";

interface OrderItemsProps {
  items: OrderItemInterface[];
  orderingCurrency?: string;
  loading: boolean;
}

const OrderItems: React.FC<OrderItemsProps> = (props) => {
  const { items, loading, orderingCurrency } = props;
  const [expandedRows] = useState<Array<any>>([]);

  const columns = [
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray">
          {trans("orderItem.nameOfItem")}
        </span>
      ),
      key: "name",
      width: "30%",
      render: (record: OrderItemInterface) => {
        const properties = lodash.get(record, "variantProperties", []);
        return (
          <div>
            <div className="flex">
              <div className="mg-r-12">
                <a
                  href={lodash.get(record, "url")}
                  className="cursor-pointer"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Avatar
                    shape={"square"}
                    size={48}
                    src={
                      record.image
                        ? lodash.get(record, "image", "")
                        : IMAGE_DEFAULT
                    }
                    className={"rad-4"}
                    style={{ border: "none" }}
                  />
                </a>
              </div>
              <div>
                <div>
                  <a
                    href={lodash.get(record, "url")}
                    className="cursor-pointer text-reg-500 item-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="font-size-14">
                      {lodash.get(record, "name", "---")}
                    </span>
                  </a>
                </div>
                <div className="flex mg-t-4">
                  {properties.length > 0
                    ? properties.map(
                        (pro: VariantPropertiesInterface) => (
                          <span key={pro.id} className=" mg-r-20">
                            <span className="txt-color-gray font-size-12">
                              {pro.name}
                            </span>
                            <span className="txt-color-black font-size-12">
                              {pro.value}
                            </span>
                          </span>
                        )
                      )
                    : "---"}
                </div>
              </div>
            </div>
            <div className="mg-t-10  font-size-12 flex">
              <span className="txt-color-gray">Tên shop: </span>
              <span className="mg-l-4">
                {lodash.identity(lodash.get(record, "merchantName"))
                  ? lodash.get(record, "merchantName", "---")
                  : "---"}
              </span>
            </div>
            <div className="mg-t-4 font-size-12">
              <span className="txt-color-gray">Địa chỉ shop:</span>
              <span className="mg-l-4">
                {lodash.identity(lodash.get(record, "merchantContact"))
                  ? lodash.get(record, "merchantContact", "---")
                  : "---"}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray">
          Đặt / nhận
        </span>
      ),
      key: "info",
      align: "center" as "center",
      width: "10%",
      render: (record: OrderItemInterface) => {
        const orderedQuantity = lodash.identity(
          lodash.get(record, "orderedQuantity")
        )
          ? lodash.get(record, "orderedQuantity")
          : "---";
        const receivedQuantity = lodash.identity(
          lodash.get(record, "receivedQuantity")
        )
          ? lodash.get(record, "receivedQuantity", "---")
          : "---";
        return (
          <div className="">
            <span className="mg-r-6 text text-reg">{`${orderedQuantity}/${receivedQuantity}`}</span>
          </div>
        );
      },
    },
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray">
          Giá theo sản phẩm
        </span>
      ),
      key: "priceByProduct",
      align: "right" as "right",
      width: "30%",
      render: (record: OrderItemInterface) => {
        const originalPrice = formatMoneyByUnit(
          lodash.get(record, "price"),
          orderingCurrency
        );
        return (
          <span className="text-reg">
            {originalPrice}
          </span>
        );
      },
    },
    {
      title: (
        <span className="robotoregular font-size-12 line-h-22 txt-color-gray">
          Tiền hàng
        </span>
      ),
      key: "totalPrice",
      align: "right" as "right",
      width: "30%",
      render: (record: OrderItemInterface) => {
        const originalPrice = lodash.get(record, "price");
        const quantity = lodash.get(record, "orderedQuantity");
        const total: any = originalPrice! * quantity;
        return (
          <span className="text-reg">
            {formatMoneyByUnit(total, orderingCurrency)}
          </span>
        );
      },
    },
  ];
  console.log('orderingCurrency', orderingCurrency)
  const components = { body: { row: RowExtended } };

  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
      className={`main-table`}
    >
      <Table
        rowKey={(record: OrderItemInterface) => record.id}
        columns={columns}
        components={components}
        dataSource={items}
        locale={{ emptyText: <EmptyData /> }}
        className="shipmentDetail-table"
        pagination={{
          total: items.length,
          pageSize: 25,
          position: ["bottomRight"],
          locale: { items_per_page: `/ ${trans("orders.page")}` },
          hideOnSinglePage: true,
          showSizeChanger: false,
        }}
        scroll={{ x: 572 }}
        size="small"
        onRow={(record, index) => {
          const rowClassesByIdx = index! % 2 === 0 ? "row-dark" : "row-light";
          return { ...record, index, rowClassesByIdx };
        }}
        rowClassName={(record, idx) => {
          const rowClassesActiveByExpanded = lodash.includes(
            expandedRows,
            record.id
          )
            ? "active-row cursor-pointer"
            : "data-row cursor-pointer";
          const rowClassesByIdx = idx % 2 === 0 ? "row-dark " : "row-light";
          return `${rowClassesActiveByExpanded} ${rowClassesByIdx}`;
        }}
      />
    </SkeletonTable>
  );
};
export default OrderItems;

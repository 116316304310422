import { AxiosResponse } from 'axios'
import CustomerCriteria from '../Criteria/CustomerCriteria'
import { TransactionHistoryGetQueryInterface, TransactionHistoryInterface } from '../Interface/TransactionInterface'
import apiClient from '../Util/ApiClient'

export class SupplierApi {
	static getAllConnections(query: string) {
		return apiClient.get('/admin/connections/autocomplete', {
			params: { sort: 'createdAt:desc', query },
		})
	}
	static getProfiles(query: string) {
		return apiClient.get('/admin/customers/autocomplete', {
			params: { sort: 'createdAt:desc', query },
		})
	}
	static getCustomers(query: string, filter: Object = {}) {
		return apiClient.get('/admin/customers/autocomplete', {
			params: { sort: 'createdAt:desc', query, ...filter },
		})
	}
	static getAllProfiles(){
		return apiClient.get('admin/customers?limit=10000')
	}
	static customerFilter = (filter: CustomerCriteria) => {
		let limit = filter && filter.size ? filter.size : 25
		let offset = filter && filter.page ? filter.page * limit : 0
		return apiClient.get('/admin/customers', { params: { ...filter, offset, limit, sort: 'createdAt:desc' } })
	}
	static customerDetail = (username?: string) => {
		return apiClient.get(`/admin/customers/${username}`)
	}
	static getConnectionByCustomer = (username: string, provider: string) => {
		return apiClient.get(`/admin/customers/${username}/connections`, {params: {provider}})
	}
	static getDefaultAddress = (username: string) => {
		return apiClient.get(`/admin/addresses/${username}`)
	}
	static getBalanceOfConnection = (username: string, id: string) => {
		return apiClient.get(`/admin/customers/${username}/connections/${id}`)
	}
	static getTransactionHistory = (accountCode: string, query: TransactionHistoryGetQueryInterface) => {
		return apiClient.get<any, AxiosResponse< TransactionHistoryInterface[]>>(`/admin/dior/${accountCode}/transactions`, {params: query})
	}
	static getListAccount = (): Promise<any> => {
		return apiClient
			.get(`connections`, {params:{sort:'createdAt:desc'}})
	};
	
}

import { RedoOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import lodash, { identity, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SupplierApi } from "../../Api/SupplierApi";
import {
  ShipmentsCriteria,
  ShipmentsCriteriaDefault,
} from "../../Criteria/ShipmentsCriteria";
import ConnectionInterface from "../../Interface/ConnectionInterface";
import MarketPalaceInterface from "../../Interface/MarketPalaceInterface";
import ProfileInterface from "../../Interface/ProfileInterface";
import StatusInterface from "../../Interface/StatusInterface";
import { trans } from "../../resources/localization/Localization";
import {
  SELECT_CONNECTION,
  SELECT_PROFILE,
  TIME_STAMP_FROM,
  TIME_STAMP_TO,
} from "../../Util/Constants";
import ProviderInterface from "../../Interface/ProviderInterface";
import ConnectionBar from "../../Component/ConnectionBar";
import FloatLabel from "../../Component/Floatlabel";
const { Option } = Select;
const beginDateFormat = "YYYY-MM-DD HH:mm:00";
const endDateFormat = "YYYY-MM-DD HH:mm:59";
const { RangePicker } = DatePicker;

interface FilterProps {
  statuses?: Array<StatusInterface>;
  marketPalaces?: Array<MarketPalaceInterface>;
  filter: ShipmentsCriteria;
  loadingStatuses: boolean;
  loadingMarkets: boolean;
  setFilter: (obj: ShipmentsCriteria | {}) => void;
  handleFilter: (params: ShipmentsCriteria) => void;
  providers: ProviderInterface[];
}
const Filter: React.FC<FilterProps> = (props) => {
  const { statuses, handleFilter, setFilter, filter } = props;
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const [checkedStatuses, setCheckedStatuses] = useState<string[]>(
    searchParams.get("statuses")?.split(",") || []
  );
  const [showFilter, setShowFilter] = useState(true);
  const [usernameSelected, setUsernameSelected] = useState<
    string | undefined
  >();
  const [connectionSelected, setConnectionSelected] = useState<
    string | undefined
  >();
  const [connectionsFilter, setConnectionsFilter] = useState<
    Array<ConnectionInterface>
  >([]);
  const [profilesFiltered, setProfilesFiltered] = useState<
    Array<ProfileInterface>
  >([]);
  const [searchExpand, setSearchExpand] = useState(false);
//   const [timestampFrom, setTimeStampFrom] = useState<any>();
//   const [timestampTo, setTimeStampTo] = useState<any>();
//   const { width } = useWindowDimensions();

  useEffect(() => {
    const fetchData = async () => {
      if (
        searchParams.get("providerUsername") &&
        searchParams.get("providerUsername")!.length >= 3
      ) {
        const response = await SupplierApi.getAllConnections(
          searchParams.get("providerUsername")!
        );
        const conn = response.data.find(
          (conn: ConnectionInterface) =>
            conn.providerUsername === searchParams.get("providerUsername") &&
            conn.provider.code === searchParams.get("provider")
        );
        if (conn) setConnectionSelected(conn.id);
        setConnectionsFilter(response.data);
      }
      if (
        searchParams.get("username") &&
        searchParams.get("username")!.length >= 3
      ) {
        const response = await SupplierApi.getProfiles(
          searchParams.get("username")!
        );
        const profile = response.data.find(
          (profile: ProfileInterface) =>
            profile.username === searchParams.get("username")
        );
        if (profile) setUsernameSelected(profile.id);
        setProfilesFiltered(response.data);
      }
    };
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    const condition =
      searchParams.get("code") === null &&
      searchParams.get("waybill") === null &&
      searchParams.get("originalReceipt") === null &&
      searchParams.get("merchantName") === null &&
      searchParams.get("timestampFrom") === null &&
      searchParams.get("timestampTo") === null &&
      searchParams.get("page") === null &&
      searchParams.get("size") === null &&
      searchParams.get("username") === null &&
      searchParams.get("statuses") === null &&
      searchParams.get("provider") === null &&
      searchParams.get("providerUsername") === null;

    if (condition) {
      clearFilter();
    } else {
      return;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);


  const handleChangeStatuses = (key: string, selected: boolean) => {
    if (selected) {
      setCheckedStatuses([...checkedStatuses, key]);
      setFilter((prevState: ShipmentsCriteria) => ({
        ...prevState,
        statuses: [...checkedStatuses, key].join(),
      }));
    } else {
      setCheckedStatuses(checkedStatuses.filter((stt: string) => stt !== key));
      setFilter((prevState: ShipmentsCriteria) => ({
        ...prevState,
        statuses: [
          ...checkedStatuses.filter((stt: string) => stt !== key),
        ].join(),
      }));
    }
    // if (selected) {
    // 	setCheckedStatuses([...checkedStatuses, key])
    // 	setFilter({ statuses: [...checkedStatuses, key] })
    // } else {
    // 	setCheckedStatuses(checkedStatuses.filter((stt: string) => stt !== key))
    // 	setFilter({ statuses: checkedStatuses.filter((stt: string) => stt !== key) })
    // }
  };

  const onFinish = () => {
    if (searchParams.get("page") === null) {
      handleFilter({ ...filter });
    } else {
      handleFilter({ ...filter, page: 0 });
    }
  };

  const clearFilter = () => {
    form.resetFields();
    setCheckedStatuses([]);
    // setTimeStampFrom("");
    // setTimeStampTo("");
    setUsernameSelected(undefined);
    setConnectionSelected(undefined);
    setConnectionsFilter([]);
    setProfilesFiltered([]);
    handleFilter(
      lodash.pickBy(
        ShipmentsCriteriaDefault,
        (val) => !isEmpty(val) && identity(val)
      )
    );
  };

  const handleChangeValue = (key: string, value: string) => {
    if (key === SELECT_PROFILE) {
      setUsernameSelected(value);
      const username = lodash.get(
        profilesFiltered?.find((x: ProfileInterface) => x.id === value),
        "username"
      );
      setFilter({ ...filter, username });
    }
    if (key === SELECT_CONNECTION) {
      const connection = connectionsFilter?.find(
        (x: ConnectionInterface) => x.id === value
      );
      const providerUsername = lodash.get(connection, "providerUsername");
      const provider = lodash.get(connection, "provider.code");
      setConnectionSelected(value);
      setFilter({ ...filter, provider, providerUsername });
    }
  };

  const handleAutoComplete = async (key: string, value: any) => {
    if (value && value.length >= 3) {
      try {
        if (key === SELECT_CONNECTION) {
          const response = await SupplierApi.getAllConnections(value);
          setConnectionsFilter(response.data);
        }
        if (key === SELECT_PROFILE) {
          const response = await SupplierApi.getProfiles(value);
          setProfilesFiltered(response.data);
        }
      } catch (err) {}
    } else {
      if (key === SELECT_CONNECTION) {
        setConnectionsFilter([]);
      }
      if (key === SELECT_PROFILE) {
        setProfilesFiltered([]);
      }
    }
  };

  const handleClear = (key: string) => {
    if (key === SELECT_CONNECTION) {
      setFilter({ ...lodash.omit(filter, ["provider", "providerUsername"]) });
      setConnectionsFilter([]);
    }
    if (key === SELECT_PROFILE) {
      setFilter({ ...lodash.omit(filter, ["username"]) });
      setProfilesFiltered([]);
    }
  };

  const handleChangeDate = (dates: any, dateString: string[]) => {
    setFilter({
      ...filter,
      [TIME_STAMP_FROM]: dates
        ? moment(dates[0].format(beginDateFormat)).toISOString()
        : '',
      [TIME_STAMP_TO]: dates
        ? moment(dates[1].format(endDateFormat)).toISOString()
        : '',
    });
  };

  
  return (
    <Row className="shipments-filter mg-0 mg-bt-12">
      <Row
        className={`shipments-filter__title ${showFilter ? "mg-bt-20" : ""}`}
        align="middle"
      >
        <Col className="title">
          <h3>{trans("shipmentsFilter.search")}</h3>
        </Col>
        <Col className="divider">{/* <Divider className='mg-0' /> */}</Col>
        <Col>
          <i
            className={`cursor-pointer fa-solid fa-angle-${
              showFilter ? "up" : "down"
            }`}
            onClick={() => setShowFilter(!showFilter)}
          />
        </Col>
      </Row>
      {showFilter && (
        <>
          <div className={`shipments-filter__statuses rad-6`}>
            {statuses?.map(({ code, name }: StatusInterface) => {
              const selected =
                checkedStatuses && checkedStatuses.includes(code)
                  ? true
                  : false;
              return (
                <span
                  className={selected ? "status active" : "status"}
                  key={code}
                  onClick={() => {
                    handleChangeStatuses(code, !selected);
                  }}
                >
                  {name}
                </span>
              );
            })}
          </div>
          {searchExpand && (
            <>
              <Form
                name="shipment-filter"
                onFinish={onFinish}
                className="shipments-filter__form mg-bt-10"
                form={form}
              >
                <Row
                  gutter={[16, 16]}
                  align="middle"
                  className="shipments-filter__form-input"
                >
                  <Col xxl={6} md={8} sm={24} xs={24}>
                    <Input
                      className={` form-input ${
                        filter.code !== undefined ? "input-has-value" : ""
                      }`}
                      placeholder=""
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          code: e.target.value,
                        })
                      }
                      value={filter.code ? filter.code : ""}
                      allowClear
                    />
                    <label className="robotoregular form-label text-grey-400">
                      {/* {'Mã đơn hàng'} */}
                      {trans("shipmentsFilter.code")}
                    </label>
                  </Col>

                  <Col xxl={6} md={8} sm={24} xs={24}>
                    <Input
                      className={` form-input ${
                        filter.waybill !== undefined ? "input-has-value" : ""
                      }`}
                      placeholder=""
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          waybill: e.target.value,
                        })
                      }
                      value={filter.waybill ? filter.waybill : ""}
                      allowClear
                    />
                    <label className="robotoregular form-label text-grey-400">
                      {trans("shipmentsFilter.waybills")}
                    </label>
                  </Col>

                  <Col xxl={6} md={8} sm={24} xs={24}>
                    <Input
                      className={` form-input ${
                        filter.originalReceipt !== undefined
                          ? "input-has-value"
                          : ""
                      }`}
                      placeholder=""
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          originalReceipt: e.target.value,
                        })
                      }
                      value={
                        filter.originalReceipt ? filter.originalReceipt : ""
                      }
                      allowClear
                    />
                    <label className="robotoregular form-label text-grey-400">
                      {/* {'Mã hóa đơn gốc'} */}
                      {trans("shipmentsFilter.originalReceipts")}
                    </label>
                  </Col>

                  <Col xxl={6} md={8} sm={24} xs={24}>
                    <Input
                      className={` form-input ${
                        filter.merchantName !== undefined
                          ? "input-has-value"
                          : ""
                      }`}
                      // ${className ? className : ''} ${value !== '' ? 'input-has-value' : ''}
                      placeholder=""
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          merchantName: e.target.value,
                        })
                      }
                      value={filter.merchantName ? filter.merchantName : ""}
                      allowClear
                    />
                    <label className="robotoregular form-label text-grey-400">
                      {trans("shipmentsFilter.merchantName")}
                      {/* {'Tên shop'} */}
                    </label>
                  </Col>

                  <Col xxl={12} md={8} sm={24} xs={24}>
                    <FloatLabel
                      label={`${trans("shipmentsFilter.timestampFrom")}`}
                      labelRange={trans("shipmentsFilter.timestampTo")}
                      value={[
                        filter.timestampFrom
                          ? moment(filter.timestampFrom)
                          : "",
                        filter.timestampTo ? moment(filter.timestampTo) : "",
                      ]}
                      isRange={true}
                    >
                      <RangePicker
                        suffixIcon={
                          <i className="fa-solid fa-calendar-range txt-color-gray9"></i>
                        }
                        size="large"
						format={'DD-MM-YYYY HH:mm'}
                        value={[
							filter.timestampTo ? moment(filter.timestampTo) : null,
							filter.timestampFrom ? moment(filter.timestampFrom) : null,
						]}
                        placeholder={["", ""]}
                        onChange={(values: any, dateString: string[]) =>
                          handleChangeDate(values, dateString)
                        }
                        style={{ width: "100%", borderRadius: "8px" }}
                      />
                    </FloatLabel>
                  </Col>

                  <Col xxl={6} md={8} sm={24} xs={24}>
                    <Select
                      value={usernameSelected}
                      className={` form-input`}
                      allowClear={true}
                      showSearch
                      filterOption={false}
                      onSearch={lodash.debounce(
                        (val) => handleAutoComplete(SELECT_PROFILE, val),
                        1000
                      )}
                      onChange={(value) => {
                        handleChangeValue(SELECT_PROFILE, value);
                      }}
                      onClear={() => handleClear(SELECT_PROFILE)}
                      style={{ width: "100%" }}
                      notFoundContent={null}
                      placeholder={
                        <span className="robotoregular selected-label text-grey-400">
                          {/* Khách hàng */}
                          {trans("shipmentsFilter.customer")}
                        </span>
                      }
                    >
                      {profilesFiltered?.map((profile: ProfileInterface) => (
                        <Option
                          key={profile.id}
                          value={profile.id}
                        >{`${lodash.get(profile, "name")} (${lodash.get(
                          profile,
                          "username"
                        )})`}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col xxl={6} md={8} sm={24} xs={24}>
                    <Select
                      className={` form-input`}
                      style={{ width: "100%" }}
                      value={connectionSelected}
                      placeholder={
                        <span className="robotoregular selected-label text-grey-400">
                          Lựa chọn tài khoản tạo đơn
                        </span>
                      }
                      allowClear={true}
                      showSearch
                      filterOption={false}
                      onSearch={lodash.debounce(
                        (val) => handleAutoComplete(SELECT_CONNECTION, val),
                        1000
                      )}
                      onChange={(value) => {
                        handleChangeValue(SELECT_CONNECTION, value);
                      }}
                      onClear={() => handleClear(SELECT_CONNECTION)}
                      // dropdownStyle={{ minWidth: '250px' }}
                      notFoundContent={null}
                    >
                      {connectionsFilter?.map((conn: ConnectionInterface) => (
                        <Option key={conn.id} value={conn.id}>
                          <ConnectionBar
                            divider="|"
                            inFilter
                            sizeImg={15}
                            logo={conn?.provider?.logo}
                            providerName={conn?.provider?.name}
                            username={conn.providerUsername}
                            className="font-size-12"
                          />
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Form>
            </>
          )}
          <Row align="middle" justify="space-between" className="actions">
            <Col span={12}>
              <span
                className="cursor-pointer text mg-r-7"
                onClick={() => setSearchExpand(!searchExpand)}
              >{`${
                searchExpand
                  ? trans("shipmentFilter.Collapse")
                  : trans("shipmentFilter.Open")
              }`}</span>
              <i
                className={`cursor-pointer fa-solid fa-angle-${
                  searchExpand ? "up" : "down"
                }`}
                onClick={() => setSearchExpand(!searchExpand)}
              />
            </Col>
            {showFilter && (
              <Col span={12} className={"actions-btn"}>
                {!lodash.isEmpty(
                  lodash.pickBy(
                    filter,
                    (val: any) => !isEmpty(val) && identity(val)
                  )
                ) && (
                  <Button
                    className="clear"
                    onClick={clearFilter}
                    icon={<RedoOutlined />}
                  >
                    <span className="mg-0 clean-filter">
                      {trans("shipmentFilter.cleanFilter")}
                    </span>
                  </Button>
                )}
                <Button
                  className="btn btn-primary font-size-14 shipment-filter-button"
                  type="primary"
                  htmlType="submit"
                  form="shipment-filter"
                  onClick={onFinish}
                >
                  <span className="px-16 text-500 mg-bt-2">
                    {trans("shipmentFilter.search")}
                  </span>
                </Button>
              </Col>
            )}
          </Row>
        </>
      )}
    </Row>
  );
};

export default Filter;

import { ShipmentsCriteria } from '../Criteria/ShipmentsCriteria'
import { PaymentLogInterface } from '../Interface/PackageOrderInterface'
import apiClient from '../Util/ApiClient'
import { AxiosResponse } from 'axios'
const BASE_URL = '/admin/shipments'
export class ShipmentsApi {
  static filter(filter: ShipmentsCriteria) {
    let limit = filter?.size ? filter.size : 25
    let offset = filter?.page ? filter.page * limit : 0

    const params: any = {
      ...filter,
      limit,
      offset,
      sort: `timestamp:${filter.orderBy ?? 'desc'}`,
    }

    delete params.orderBy

    return apiClient.get(BASE_URL, { params })
  }

  static getWaybills(code: string) {
    return apiClient.get(`${BASE_URL}/${code}/waybills`)
  }

  static getDetails(code: string) {
    return apiClient.get(`${BASE_URL}/${code}`)
  }

  static getMilestone(code: string) {
    return apiClient.get(`${BASE_URL}/${code}/milestones`, {
      params: { limit: 1000, sort: 'timestamp:desc' },
    })
  }

  static getItems(code: string) {
    return apiClient.get(`${BASE_URL}/${code}/products`, {
      params: { limit: 1000, sort: 'timestamp:desc' },
    })
  }

  static getFees(code: string) {
    return apiClient.get(`${BASE_URL}/${code}/fees`)
  }
  static updateStatus(code: string, status: string) {
    return apiClient.patch(`${BASE_URL}/${code}/update_statuses`, { status })
  }
  static getTransactions(code: string) {
    return apiClient.get(`admin/shipments/${code}/financials`, {
      params: { limit: 1000, sort: 'timestamp:desc' },
    })
  }
  static getPaymentRequests(code: string) {
    return apiClient.get(`admin/payment-requests`, {
      params: { size: 1000, shipmentCode: code },
    })
  }
  static updateStatusPaymentRequest(id: string, paymentStatus: string) {
    return apiClient.post(`admin/payment-requests/${id}/change-status`, {
      paymentStatus,
    })
  }
  static updateMethodPaymentRequest(id: string, paymentMethod: string) {
    return apiClient.post(`admin/payment-requests/${id}/change-method`, {
      paymentMethod,
    })
  }
  static getPackagesOfShipments(code: string) {
    return apiClient.get(`admin/shipments/${code}/packages`, {
      params: { sort: 'createdAt:desc' },
    })
  }

  static getMilestoneOfShipments(code: string, packageCode: string) {
    return apiClient.get(`admin/shipments/${code}/packages/${packageCode}/milestones`, {
      params: { code, packageCode, sort: 'createdAt:desc' },
    })
  }
  static getLogs = (code: string, filter: any): Promise<AxiosResponse<PaymentLogInterface[]>> => {
    return apiClient.get(`admin/shipments/${code}/logs`, {
      params: { sort: 'timestamp:desc' },
    })
  }

  static getMidmileList(provider: string) {
    return apiClient.get(`/admin/mid-mile?offset=0&limit=1000&provider=${provider}`)
  }

  static updateMidmile(shipmentCode: string, midMiles: string[]) {
    return apiClient.patch(`/admin/shipments/${shipmentCode}/mid-mile`, { midMiles })
  }
}

import { Image, Steps } from 'antd'
import lodash, { reverse } from 'lodash'
import React, { ReactNode, useEffect, useState } from 'react'
import MilestoneInterface from '../../Interface/MilestoneInterface'
import StatusInterface from '../../Interface/StatusInterface'
import StepIconFinishedImg from '../../resources/images/icons/step-finish.png'
import StepIconImg from '../../resources/images/icons/step.png'
import { formatDateTime } from '../../Util/string'
import { trans } from '../../resources/localization/Localization'
import Skeleton from 'react-loading-skeleton'
import ShipmentDetailInterface from '../../Interface/ShipmentDetailInterface'
import useWindowDimensions from '../../Util/dimention'

interface CustomDescriptionInterface {
	timestamp: string
	handlingTime?: string
}

type StepsOmitType = {
	status?: 'wait' | 'process' | 'finish' | 'error'
}
const { Step } = Steps
interface TrackOrderProps {
	statuses: StatusInterface[]
	details?: ShipmentDetailInterface
	milestones: MilestoneInterface[]
	loading: boolean
	getMilestones: () => void
}

const TrackOrder: React.FC<TrackOrderProps> = (props) => {
	const { width } = useWindowDimensions()
	const { milestones, loading, statuses } = props

	const [milestonesRendered, setMileStoneRendered] = useState<MilestoneInterface[]>([])

	useEffect(() => {
		if (width < 1024) {
			// setMileStoneRendered([...reverse(milestones)])
			setMileStoneRendered([...reverse(milestones)])
		} else {
			setMileStoneRendered(milestones)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [milestones])

	const customDot = (iconDot: ReactNode, { status }: StepsOmitType) => {
		return !loading ? (
			<Image preview={false} src={status !== 'process' ? StepIconFinishedImg : StepIconImg} />
		) : (
			<Skeleton width={10} height={10} circle />
		)
	}

	const CustomDescription = ({ timestamp, handlingTime }: CustomDescriptionInterface) => {
		return (
			<span className='flex flex-col items-start justify-start line-h-20 font-size-14'>
				<span className=' '>{!loading ? timestamp : <Skeleton width={120} height={20} />}</span>
				{handlingTime &&
					(!loading ? (
						<span className=''>{`(${handlingTime})`}</span>
					) : (
						<Skeleton width={80} height={20} />
					))}
			</span>
		)
	}
	return width < 1024 ? (
		<>
			<Steps
				className={`${width < 1024 ? 'package-timeline ' : ''}`}
				current={width > 1024 ? 0 : milestonesRendered.length - 1}
				// current={}
				responsive={false}
				progressDot={customDot}
				direction={`${width < 1024 ? 'horizontal' : 'vertical'}`}
			>
				{milestonesRendered &&
					milestonesRendered.length > 0 &&
					milestonesRendered.map(({ status, timestamp, handlingTime, id }: MilestoneInterface) => {
						const nameOfStatus = lodash.get(
							statuses?.find(({ code }: StatusInterface) => code === status),
							'name',
							'---'
						)
						const timestampRendered = lodash.isNull(timestamp)
							? trans('order.undefined')
							: formatDateTime(timestamp)
						const handlingTimeRendered = lodash.isNull(handlingTime)
							? trans('order.undefined')
							: `${handlingTime} ngày`
						return (
							<Step
								key={id}
								title={
									<span className='text-500 font-size-14'>
										{!loading ? nameOfStatus : <Skeleton width={80} height={22} />}
									</span>
								}
								description={
									<CustomDescription
										timestamp={timestampRendered}
										handlingTime={handlingTimeRendered}
									/>
								}
							/>
						)
					})}
			</Steps>
		</>
	) : (
		<Steps
			className={`${width < 1024 ? '' : ''}`}
			current={width > 1024 ? 0 : milestonesRendered.length - 1}
			// current={milestonesRendered}
			progressDot={customDot}
			direction={`${width < 1024 ? 'horizontal' : 'vertical'}`}
		>
			{milestonesRendered &&
				milestonesRendered.length > 0 &&
				milestonesRendered.map(({ status, timestamp, handlingTime, id }: MilestoneInterface) => {
					const nameOfStatus = lodash.get(
						statuses?.find(({ code }: StatusInterface) => code === status),
						'name',
						'---'
					)

					const timestampRendered = lodash.isNull(timestamp)
						? trans('order.undefined')
						: formatDateTime(timestamp)
					const handlingTimeRendered = lodash.isNull(handlingTime)
						? trans('order.undefined')
						: `${handlingTime} ngày`
					return (
						<Step
							key={id}
							title={
								<span className='text-500 font-size-14'>
									{!loading ? nameOfStatus : <Skeleton width={80} height={22} />}
								</span>
							}
							description={
								<CustomDescription timestamp={timestampRendered} handlingTime={handlingTimeRendered} />
							}
						/>
					)
				})}
		</Steps>
	)
}

export default TrackOrder


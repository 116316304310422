import { Divider, Tooltip } from 'antd'
import { trans } from '../../../resources/localization/Localization'
import lodash from 'lodash'
import { ServiceInterface } from '../../../Interface/OrderDetailInterface'
import Skeleton from 'react-loading-skeleton'
import { FC } from 'react'
import Paragraph from 'antd/lib/typography/Paragraph'
import Item from './Item'

interface IProps {
  expand: boolean
  loading: boolean
  details: any
  displayAddress: string
  listServices: any
  originalReceipts: any
  refShipmentCode: string
  refCustomerCode: string
  personalNote: string
  employeeNote: string
  chargedWeight: string
  receivingWarehouseAddressReplace: string
}

export const DesktopView: FC<IProps> = ({
  expand,
  loading,
  details,
  displayAddress,
  listServices,
  originalReceipts,
  refShipmentCode,
  refCustomerCode,
  personalNote,
  employeeNote,
  chargedWeight,
  receivingWarehouseAddressReplace,
}) => {
  return (
    <div className={`shipment-content__text-info pd-12 ${!expand ? 'py-0' : ''}`}>
      {!loading ? <h3>{trans('shipments.information')}</h3> : <Skeleton width={120} height={22} />}
      {expand && (
        <div className="flex flex-col gap-12">
          <div className="flex flex-col gap-8">
            <Item
              // label={`${trans('shipment.receiver')}:`}
              label={<span className="txt-color-gray1 font-size-14">{trans('shipments.receiver')}:</span>}
              content={<span className="text-400 font-size-14">{lodash.get(details, 'address.receiver', '---')}</span>}
              loading={loading}
              widthContent={80}
              widthLabel={60}
            />
            <Item
              // label={`${trans('shipment.phoneNumber')}:`}
              label={<span className="txt-color-gray1 font-size-14">{trans('shipments.phoneNumber')}:</span>}
              content={
                <span className="text-400 font-size-14">{lodash.get(details, 'address.phoneNumber', '---')}</span>
              }
              widthContent={80}
              widthLabel={60}
              loading={loading}
            />
            <Item
              label={<span className="txt-color-gray1 font-size-14">{trans('shipments.address')}:</span>}
              content={
                <span className="text-400 font-size-14">
                  {lodash.get(details, 'address.receiver', '---')},{displayAddress}
                </span>
              }
              loading={loading}
              widthLabel={60}
              containerClassContent={'flex-grow-1'}
            />
          </div>
          <Divider type="horizontal" className="mg-0" />
          <div className="flex flex-col gap-8">
            <Item
              label={
                <span className="txt-color-gray1 flex-basis-pc-50 font-size-14">{trans('shipments.services')}:</span>
              }
              content={
                listServices.length > 0
                  ? listServices.map((item: ServiceInterface, idx: number) => {
                      let colorService = ''
                      if (item.needApprove) {
                        if (item.approved === null || item.approved === undefined) {
                          colorService = 'services-pending'
                        } else if (item.approved === false) {
                          colorService = 'services-reject'
                        }
                      }
                      return (
                        <span key={idx} className={`mg-r-4 ${colorService} font-size-14`}>
                          {`${item.name} ${idx !== listServices.length - 1 ? '|' : ''}`}{' '}
                        </span>
                      )
                    })
                  : '---'
              }
              loading={loading}
              widthLabel={60}
              containerClassContent="flex-grow-1"
            />
          </div>
          <Divider type="horizontal" className="mg-0" />

          {!loading ? (
            <div className="flex">
              <div className="flex flex-basis-50pc flex-col flex-grow-1">
                <span className="txt-color-gray1 font-size-14">{trans('shipments.originalInvoiceCode')}:</span>
                <div className="font-size-14">{originalReceipts}</div>
                <span className="txt-color-gray1 font-size-14 mg-t-12">{trans('shipments.customerCode')}:</span>
                <div className="font-size-14">{refShipmentCode}</div>
              </div>
              <div className="flex-basis-50pc">
                <span className="txt-color-gray1 font-size-14">{trans('shipments.customerOrderCode')}:</span>
                <div className="font-size-14">{refCustomerCode}</div>
              </div>
            </div>
          ) : (
            <div className="flex">
              <div className="flex flex-basis-50pc flex-col flex-grow-1">
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
              </div>
              <div className="flex-basis-50pc">
                <Skeleton width={1000} height={14} />
                <Skeleton width={1000} height={14} />
              </div>
            </div>
          )}

          <Divider type="horizontal" className="mg-0" />

          {!loading ? (
            <div className="flex flex-col flex-grow-1">
              <span className="txt-color-gray1 font-size-14">{trans('shipments.chargedWeight')}:</span>
              <div className="font-size-14">{chargedWeight}</div>
            </div>
          ) : (
            <div className="flex flex-col flex-grow-1">
              <Skeleton width={1000} height={14} />
              <Skeleton width={1000} height={14} />
            </div>
          )}
          <Divider type="horizontal" className="mg-0" />
          {!loading ? (
            <>
              <div>
                <span className="txt-color-gray1 font-size-14">
                  {trans('shipments.employeeNote')}:{' '}
                  <span className="font-size-14 txt-color-black">{personalNote}</span>
                </span>
              </div>
              <div>
                <span className="txt-color-gray1 font-size-14">
                  {trans('shipments.note')}: <span className="font-size-14 txt-color-black">{employeeNote}</span>
                </span>
              </div>
            </>
          ) : (
            <>
              <Skeleton width={1000} height={14} />
              <Skeleton width={1000} height={14} />
            </>
          )}
          <Divider type="horizontal" className="mg-0" />

          <div className="basic-item dpl-block">
            <div className="basic-item__label mg-bt-8">
              {!loading ? (
                <span className="txt-color-gray1 font-size-14">Địa chỉ kho nhận tại Trung Quốc:</span>
              ) : (
                <Skeleton />
              )}
              {!loading ? (
                <span className="txt-size-h7 align-items-baseline info-item">
                  <Paragraph
                    className="mg-0 pd-0 order-heading white-space-pre-wrap-impt"
                    copyable={
                      details?.receivingWarehouse?.detailWarehouse
                        ? {
                            text: receivingWarehouseAddressReplace,
                            icon: [
                              <Tooltip key={'dsad'} title={<span className=" fsz-12  line-h-20">Copy</span>} color="#FBB739">
                                <i className="far fa-copy line-h-14" />
                              </Tooltip>,
                              <Tooltip key={'zvcxzz'} title={<span className=" fsz-12  line-h-20">Copied</span>} color="#FBB739">
                                <i className="far fa-check fsz-12 line-h-14" />
                              </Tooltip>,
                            ],
                            tooltips: [false, false],
                          }
                        : false
                    }
                  >
                    {receivingWarehouseAddressReplace}
                  </Paragraph>
                </span>
              ) : (
                <Skeleton />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

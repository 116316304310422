import { Avatar, Divider, Table, Tooltip } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import lodash from 'lodash'
import React, { ReactNode, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import ProviderInterface from '../../../Interface/ProviderInterface'
import StatusInterface from '../../../Interface/StatusInterface'
import { formatDateTime1, formatMoneyByUnit } from '../../../Util/string'
import IMAGE_DEFAULT from '../../../resources/images/empty-image.jpg'
import HeaderList from './HeaderList'
import RowExtended from './RowExtend'
import { trans } from '../../../resources/localization/Localization'
import EmptyData from '../../../Component/EmptyData'
import SkeletonTable from '../../../Component/SkeletonTable'
import ShipmentInterface from '../../../Interface/ShipmentInterface'
import { SHIPMENT_VIEW_MIDMILE_PARTNER } from '../../../Config/permission'
import SecurityService from '../../../Util/SecurityService'
import { MidmileView } from '../../../Component/views/MidmileView'

interface DataTableProps {
  isSkeleton: boolean
  total: number
  statuses: any[]
  providers: any[]
  data: any[]
  typeDataTable: string
  handleTypeTableData: (val: string) => void
  renderProviderLink: (provider: string, code: string) => ReactNode
}
const DataTable: React.FC<DataTableProps> = (props) => {
  const { handleTypeTableData, total, data, isSkeleton, statuses, providers, typeDataTable, renderProviderLink } = props
  const [expandedRows] = useState<Array<any>>([])

  const columns = useMemo(() => {
    const columns = [
      {
        title: (
          <span className="robotoregular font-size-14 line-h-22 txt-color-gray">{trans('shipment.codeTitle')}</span>
        ),
        width: '35%',
        render: (item: any) => {
          const provider = providers.find((prod: ProviderInterface) => prod.code === item.provider)
          return (
            <div className="flex items-center font-size-14">
              <Avatar
                src={item.image ? item?.image : IMAGE_DEFAULT}
                shape="square"
                size={56}
                className="bd-none-impt rad-6 mg-r-20"
              />
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <Link to={`/shipments/${item.code}`} className="mg-r-16 ">
                    <Paragraph
                      className="robotomedium mg-0 txt-color-view font-size-14 line-h-22 whitespace"
                      copyable={{
                        text: item.code,
                        icon: [
                          <Tooltip key={'bxc'} title={<span className="">Copy</span>} color="#CD5E77">
                            <i className="far fa-copy line-h-14"></i>
                          </Tooltip>,
                          <Tooltip key={'gkg'} title={<span className="">Copied</span>} color="#CD5E77">
                            <i className="far fa-check fsz-12 line-h-14"></i>
                          </Tooltip>,
                        ],
                        tooltips: [false, false],
                      }}
                    >
                      <span className="text-500">{`#${item.code}`}</span>
                    </Paragraph>
                  </Link>
                  {renderProviderLink(item.provider, item.providerShipmentCode)}
                </div>
                <div className={'flex justify-between font-size-14 mg-t-4'}>
                  <div>
                    <Avatar size={20} src={provider?.logo} shape={'circle'} className="bd-none-impt mg-r-4" />
                    <span className="txt-color-black fsz-14 line-h-22">{provider?.name}</span>
                    <Divider type={'vertical'} className={'mx-10 bd-color-black h-16 line-h-22 mx-4'} />
                    <span className={'robotoregular fsz-14 line-h-22'}>{item.providerUsername}</span>
                  </div>
                  <div>
                    <span className="mg-r-3">{`${item.orderedQuantity ? item.orderedQuantity : '---'} / ${
                      item.receivedQuantity ? item.receivedQuantity : '---'
                    }`}</span>
                  </div>
                </div>
              </div>
            </div>
          )
        },
      },
      {
        title: 'Vận chuyển Midmile',
        dataIndex: 'midMileView',
        width: '20%',
        render: (midMileView: ShipmentInterface['midMileView']) => {
          return <MidmileView midMileView={midMileView} />
        },
      },
      {
        title: (
          <span className="robotoregular font-size-14 line-h-22 txt-color-gray">{trans('shipments.provider')}</span>
        ),
        align: 'left' as 'left',
        width: '20%',
        render: (item: any) => {
          return (
            <>
              <div>
                <span className="font-size-14 txt-color-gray">Khách hàng: </span>
                <span className={`font-size-14 ${item.username ? ' text-500' : ''}`}>
                  {item.username ? item.username : '---'}
                </span>
              </div>
              <div className="mg-t-4">
                <span className="font-size-14 txt-color-gray">Mã đơn hàng khách hàng: </span>
                <span className={`font-size-14 ${item.refShipmentCode ? ' text-500' : ''}`}>
                  {item.refShipmentCode ? item.refShipmentCode : '---'}
                </span>
              </div>
            </>
          )
        },
      },
      {
        title: <span className="robotoregular font-size-14 line-h-22 txt-color-gray">Cân nặng tính phí</span>,
        align: 'right' as 'right',
        width: '10%',
        render: (item: any) => {
          return (
            <>
              <span className={`font-size-14 ${item.actualWeight ? 'text-500' : ''}`}>
                {item.actualWeight ? `${item.actualWeight} kg` : '---'}
              </span>
            </>
          )
        },
      },
      {
        title: (
          <span className="robotoregular font-size-14 line-h-22 txt-color-gray">{trans('shipments.needPaid')}</span>
        ),
        align: 'right' as 'right',
        width: '20%',
        render: (item: any) => {
          return (
            <span className={`font-size-14 txt-color-red ${item.totalUnpaid ? ' text-500' : ''} `}>
              {item.totalUnpaid ? formatMoneyByUnit(item.totalUnpaid) : <span className="txt-color-gray">---</span>}
            </span>
          )
        },
      },
      {
        title: (
          <span className="robotoregular font-size-14 line-h-22 txt-color-gray mg-r-15">
            {trans('shipments.statusOfShipment')}
          </span>
        ),
        align: 'right' as 'right',
        width: '25%',
        render: (item: any) => {
          const status = statuses.find((stt: StatusInterface) => stt.code === item.status)
          return (
            <div className="mg-t-10">
              <span
                className="line-h-22 rad-25 txt-color-white px-16 py-6 txt-capitalize font-size-14"
                style={{
                  backgroundColor: `${status?.color ? status.color : '#0082E6'}`,
                  whiteSpace: 'nowrap',
                }}
              >
                {status?.name}
              </span>
              <div className=" mg-t-12">
                <span className="font-size-14 line-h-22 txt-color-gray mg-r-15">
                  {item.timestamp ? formatDateTime1(item.timestamp) : '---'}{' '}
                </span>
              </div>
            </div>
          )
        },
      },
    ]

    if (!SecurityService.can(SHIPMENT_VIEW_MIDMILE_PARTNER)) {
      columns.splice(1, 1)
    }
    return columns
  }, [providers, renderProviderLink, statuses])

  const components = { body: { row: RowExtended } }

  return (
    <SkeletonTable loading={isSkeleton} columns={columns}>
      <Table
        rowKey={(item: any) => item.id}
        columns={columns}
        dataSource={data}
        pagination={false}
        className="table-improved font-size-14"
        components={components}
        onRow={(item, index) => {
          const rowClassesByIdx = index! % 2 === 0 ? 'row-dark' : 'row-light'
          return { ...item, index, rowClassesByIdx }
        }}
        title={() => (
          <HeaderList
            total={total}
            typeDataTable={typeDataTable}
            setTypeDataTable={(val: string) => handleTypeTableData(val)}
          />
        )}
        locale={{ emptyText: <EmptyData text={trans('orders.empty')} /> }}
        scroll={{ x: 526 }}
        rowClassName={(item, idx) => {
          const rowClassesActiveByExpanded = lodash.includes(expandedRows, item.id)
            ? 'active-row cursor-pointer'
            : 'data-row cursor-pointer'
          const rowClassesByIdx = idx % 2 === 0 ? 'row-dark' : 'row-light'
          return `${rowClassesActiveByExpanded} ${rowClassesByIdx}`
        }}
      />
    </SkeletonTable>
  )
}

export default DataTable

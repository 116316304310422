import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CategoryApi } from '../../Api/CategoryApi'
import MilestoneInterface from '../../Interface/MilestoneInterface'
import OrderItemInterface from '../../Interface/OrderItemInterface'
import StatusInterface from '../../Interface/StatusInterface'
import DefaultLayout from '../../Component/Layout/Default'
import Finance from './Finance'
import Heading from './Heading/Heading'
import Information from './Information'
import TabLayout from './TabLayout'
import TrackOrder from './TrackOrder'
import ProviderInterface from '../../Interface/ProviderInterface'
import { trans } from '../../resources/localization/Localization'
import SecurityService from '../../Util/SecurityService'
import { PERMISSION_SHIPMENT_HISTORY_VIEW } from '../../Config/permission'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ShipmentsApi } from '../../Api/ShipmentsApi'
import ShipmentDetailInterface from '../../Interface/ShipmentDetailInterface'
import { Row } from 'antd'
import useWindowDimensions from '../../Util/dimention'
import { useProvider } from '../../hooks/fetch/useProvider'

const ShipmentDetail = () => {
	const { code } = useParams()

	const [details, setDetails] = useState<ShipmentDetailInterface>()
	const [statuses, setStatuses] = useState<StatusInterface[]>([])
	const {providerNameMapping, providers, getOrderShipmentLink} = useProvider()
	const [milestones, setMilestones] = useState<Array<MilestoneInterface>>([])
	const [items, setItems] = useState<Array<OrderItemInterface>>([])
	const [currencyOfProvider, setCurrencyProvider] = useState('')
	const [transactions, setTransactions] = useState([])
	const [loadingTransactions, setLoadingTransactions] = useState(false)
	const [packages, setPackages] = useState([])
	const [loadingPackages, setLoadingPackages] = useState(false)
	const [loading, setLoading] = useState(false)
	const [expand, setExpand] = useState(true)
	const [fees, setFees] = useState([])

	const getDetails = useCallback(async () => {
		setLoading(true)
		try {
			const response = await ShipmentsApi.getDetails(code!)
			setDetails(response.data)
			setLoading(false)
		} catch (err) {
			setLoading(false)
		}
	}, [code])

	const getStatuses = useCallback(async () => {
		try {
			const response = await CategoryApi.getShipmentStatuses()
			setStatuses(response.data)
		} catch (err) {}
	}, [])

	const getMilestones = useCallback(async () => {
		try {
			const response = await ShipmentsApi.getMilestone(code!)
			setMilestones(response.data)
		} catch (err) {}
	}, [code])

	const getItems = useCallback(async () => {
		try {
			const response = await ShipmentsApi.getItems(code!)
			setItems(response.data.content)
		} catch (err) {}
	}, [code])

	const getFees = useCallback(async () => {
		try {
			const response = await ShipmentsApi.getFees(code!)
			setFees(response.data)
		} catch (err) {}
	}, [code])

	const getTransactions = useCallback(async () => {
		setLoadingTransactions(true)
		try {
			const response = await ShipmentsApi.getTransactions(code!)
			setTransactions(response.data)
			setLoadingTransactions(false)
		} catch (err) {
			setLoadingTransactions(false)
		}
	}, [code])

	const fetchPackages = useCallback(async () => {
		setLoadingPackages(true)
		try {
			const response = await ShipmentsApi.getPackagesOfShipments(code!)
			setPackages(response.data)
			setLoadingPackages(false)
		} catch (err) {
			setLoadingPackages(false)
		}
	}, [code])

	const confirmProductChange = (code: string, id: string, confirm: boolean, successInfo: string) => {}

	useEffect(() => {
		const provider = providers.find((x: ProviderInterface) => x.code === details?.provider)
		if (provider) {
			const { code } = JSON.parse(lodash.get(provider, 'currency'))
			setCurrencyProvider(code)
		}
	}, [details?.provider, providers])

	useEffect(() => {
		getDetails()
		getItems()
		fetchPackages()
		getMilestones()
		getStatuses()
		getTransactions()
		getFees()
	}, [
		getDetails,
		getItems,
		getMilestones,
		getStatuses,
		getTransactions,
		fetchPackages,
		getFees,
	])

	const { width } = useWindowDimensions()
	return (
		<DefaultLayout title={`${trans('shipment.details')} #${code}`}>
			<div className='shipment'>
				<Heading
					statuses={statuses}
					details={details}
					providers={providers}
					getDetails={getDetails}
					setDetails={setDetails}
					currencyOfProvider={currencyOfProvider}
					loading={loading}
					providerNameMapping={providerNameMapping}
					getOrderShipmentLink={getOrderShipmentLink}
				/>
				<Row className='shipment-content mg-t-12 gap-12 flex-nowrap'>
					<div className='shipment-content__info flex flex-col flex-basis-80pc flex-wrap justify-start'>
						<div
							className={`shipment-content__info-finance flex ${
								expand && width < 1024 && width > 768 ? 'pd-bt-40' : ''
							} ${
								!expand ? 'items-center pd-12 h-60' : width < 1024 ? 'pd-12' : 'pd-t-12 pd-bt-40 px-24'
							}`}
						>
							<Information
								details={details}
								providers={providers}
								currencyOfProvider={currencyOfProvider}
								expand={expand}
								loading={loading}
							/>
							<Finance
								fees={fees}
								details={details}
								currencyOfProvider={currencyOfProvider}
								expand={expand}
								loading={loading}
							/>
							<div
								className={`${expand ? 'shipment-expand' : 'shipment-collapse'} ${
									loading ? 'flex align-items-center' : ''
								} fsz-12`}
								onClick={() => setExpand(!expand)}
							>
								{!loading ? (
									<i className={`mg-r-6 fa-solid fa-angle-${expand ? 'up' : 'down'}`}></i>
								) : (
									<Skeleton width={14} height={14} className='mg-r-6' />
								)}
								{!loading ? (
									// trans(`shipment.${expand ? 'collapse' : 'noCollapse'}`)
									expand ? (
										width < 769 ? (
											''
										) : (
											trans('shipments.expand')
										)
									) : width < 769 ? (
										''
									) : (
										trans('shipments.collapse')
									)
								) : (
									<Skeleton width={50} height={20} />
								)}
							</div>
						</div>
						<div>
							<TabLayout
								confirmProductChange={confirmProductChange}
								items={items}
								transactions={transactions}
								loadingTransactions={loadingTransactions}
								providers={providers}
								currencyOfProvider={currencyOfProvider}
								updateDetail={getDetails}
								fetchMileStone={getMilestones}
								packages={packages}
								loadingPackages={loadingPackages}
								loading={loading}
								details={details}
							/>
						</div>
					</div>
					{SecurityService.can(PERMISSION_SHIPMENT_HISTORY_VIEW) && (
						<div className='shipment-milestones h-fit-content flex-basis-20pc'>
							<h2 className='heading white-nowrap font-size-16 line-h-24'>
								{trans('shipment.milestones')}
							</h2>
							<TrackOrder
								milestones={milestones}
								statuses={statuses}
								details={details}
								getMilestones={getMilestones}
								loading={loading}
							/>
						</div>
					)}
				</Row>
			</div>
		</DefaultLayout>
	)
}

export default ShipmentDetail


import { Button, Card, DatePicker, Form, Modal, Table } from 'antd'
import { trans } from '../../../resources/localization/Localization'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { FC, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SupplierApi } from '../../../Api/SupplierApi'
import { TransactionHistoryInterface } from '../../../Interface/TransactionInterface'
import { formatDateTime, formatMoneyByUnit } from '../../../Util/string'
import { RedoOutlined } from '@ant-design/icons'
import { RangeValue } from 'rc-picker/lib/interface'
import { Moment } from 'moment'

interface IProps {
  id: string | undefined
  provider: string | undefined
  currency: string
  onCancel: () => void
}

export const TransactionHistoryModal: FC<IProps> = ({ id, provider, currency, onCancel }) => {
  const { username } = useParams()
  const [transactionHistory, setTransactionHistory] = useState<TransactionHistoryInterface[]>([])
  const [dates, setDates] = useState<RangeValue<any>>()
  const [accountCode, setAccountCode] = useState()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
  })

  const scrollRef = useRef<HTMLDivElement | null>(null); // Sử d

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "auto" }) // Cuộn lên đầu khi mở modal
    }
  }, [id, pagination]);

  const fetchTransactionHistory = async (
    params: { timestamp?: typeof dates; page?: number; limit?: number } = {}
  ) => {
    if (!accountCode || !provider) return
    const { page = 0, limit = 25 } = params
    try {
      setLoading(true)
      const timestamp = 'timestamp' in params ? params.timestamp : dates
      const transactionHistoryRes = await SupplierApi.getTransactionHistory(accountCode, {
        provider,
        nominalTimestampFrom: (timestamp?.[0] as Moment)?.startOf('D').toISOString(),
        nominalTimestampTo: (timestamp?.[1] as Moment)?.endOf('D').toISOString(),
        limit: limit,
        offset: page * limit,
        sort: 'actualTimestamp:desc'
      })
      setTransactionHistory(transactionHistoryRes.data)
      setPagination({
        pageSize: limit,
        current: page + 1,
        total: Number(transactionHistoryRes.headers['x-total-count']),
      })
    } finally {
      setLoading(false)
    }
  }

  const onSearch = () => {
    fetchTransactionHistory()
  }

  const getAccountCode = async () => {
    if (!id || !provider) {
      return
    }
    try {
      const res = await SupplierApi.getBalanceOfConnection(username!, id)
      setAccountCode(res.data.code)
    } catch (err) { }
  }

  useEffect(() => {
    if (accountCode) {
      fetchTransactionHistory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountCode])

  useEffect(() => {
    getAccountCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, provider])

  const columns: ColumnsType<any> = [
    {
      // title: trans('common.transaction'),
      title: 'Thời gian/Loại GD/Mã GD',
      dataIndex: 'txid',
      key: 'txid',
      width: '25%',
      render: (code, row: TransactionHistoryInterface) => {
        return (
          <div className="grid gap-4">
            <div>
              <span className="text-muted">{trans('common.time')}:</span> {formatDateTime(row.nominalTimestamp)}
            </div>
            <div>
              <span className="text-muted">Loại GD:</span> {trans('transactionType.' + row.type)}
            </div>
            <div>
              <span className="text-muted">{trans('common.transactionCode')}:</span> {code}
            </div>
          </div>
        )
      },
    },
    {
      title: trans('customer.actor'),
      dataIndex: 'teller',
      key: 'teller',
      width: '15%',
    },
    {
      // title: trans('common.content'),
      title: 'Nội dung GD/Ghi chú',
      dataIndex: 'memo',
      key: 'memo',
      width: '20%',
      render: (memo, row: TransactionHistoryInterface) => {
        return (
          <>
            <span>{memo}</span>
            <div className="mg-t-4">
              <span className="text-muted">Ghi chú:</span> {row.note || '---'}
            </div>
          </>
        )
      },
    },
    {
      title: trans('common.numberOfMoney'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: '20%',
      render: (value, row: TransactionHistoryInterface) => {
        const isIncrement = row.amount >= 0

        return (
          <span className={`${isIncrement ? 'text-success' : 'text-red'} font-medium`}>
            {formatMoneyByUnit(value || 0, currency)}
          </span>
        )
      },
    },
    {
      title: trans('common.balance'),
      dataIndex: 'balanceAfter',
      key: 'balanceAfter',
      align: 'right',
      width: '20%',
      render: (value, row) => {
        const isIncrement = row.balanceAfter >= 0

        return <span className={`font-medium ${isIncrement ? 'text-success' : 'text-red'}`} >{formatMoneyByUnit(value || 0, currency)}</span>
      },
    },
  ]

  const isDisableSubmit = !dates?.[0] && !dates?.[1]

  const clearFilter = () => {
    setDates(undefined)
    fetchTransactionHistory({ timestamp: undefined })
  }

  const onChangePagination = (page: number, pageSize: number) => {
    fetchTransactionHistory({
      limit: pageSize,
      page: page - 1,
    })
  }

  return (
    <Modal
      className='modal'
      open={!!id}
      onCancel={() => {
        setDates(undefined)
        setAccountCode(undefined)
        onCancel()
      }}
      closeIcon={<i className="h-16 far fa-times w-9 line-h-16 txt-color-black"></i>}
      centered
      footer={null}
      width={1100}
      title={trans('common.transactionHistory')}
    >
      <div className="grid gap-12" ref={scrollRef}>
        <Card title={trans('common.search')} size="small">
          <Form.Item label={trans('customer.happeningTime')} className="mg-bt-0">
            <DatePicker.RangePicker
              onCalendarChange={(value) => {
                setDates([value?.[0] || null, value?.[1] || null])
              }}
              value={dates}
              placeholder={['Từ ngày', 'Đến ngày']}
            />
          </Form.Item>

          <div className="flex items-center justify-end gap-8">
            {!isDisableSubmit && (
              <Button className="clear" onClick={clearFilter} icon={<RedoOutlined />} type="text">
                <span className="mg-l-4">{trans('orders.cleanFilter')}</span>
              </Button>
            )}

            <Button type="primary" onClick={onSearch} disabled={isDisableSubmit}>
              Tìm kiếm
            </Button>
          </div>
        </Card>

        <Card size="small" className="transaction-history-card">
          <Table
            size="small"
            columns={columns}
            loading={loading}
            dataSource={transactionHistory}
            scroll={{ x: 1024 }}
            pagination={{ size: 'default', onChange: onChangePagination, pageSizeOptions: [10, 25, 50, 100], ...pagination }}
          />
        </Card>
      </div>
    </Modal>
  )
}

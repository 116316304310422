import React from 'react'
import Skeleton from 'react-loading-skeleton'
import ShipmentFeeInterface from '../../../Interface/FeeInterface'
import ShipmentDetailInterface from '../../../Interface/ShipmentDetailInterface'
import FeeBox from './FeeBox'
import { trans } from '../../../resources/localization/Localization'

interface FinanceProps {
  details?: ShipmentDetailInterface
  currencyOfProvider: string
  expand: boolean
  loading: boolean
  fees: ShipmentFeeInterface[]
}
const Finance = (props: FinanceProps) => {
  const { details, currencyOfProvider, expand, loading, fees } = props
  return (
    <div className={`${expand ? 'mg-bt-24' : 'py-0'} shipment-content__text-finance pd-12`}>
      {expand ? (
        <h2 className="mg-0">{!loading ? trans('shipments.finance') : <Skeleton width={120} height={22} />}</h2>
      ) : (
        ''
      )}
      {expand && <FeeBox fees={fees} currencyOfProvider={currencyOfProvider} details={details} loading={loading} />}
    </div>
  )
}

export default Finance

import { Avatar, Table } from 'antd'
import lodash, { map, sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { CategoryApi } from '../../../../Api/CategoryApi'
import EmptyData from '../../../../Component/EmptyData'
import SkeletonTable, { SkeletonTableColumnsType } from '../../../../Component/SkeletonTable'
import PackageStatusInterface from '../../../../Interface/PackageStatusesInterface'
import { trans } from '../../../../resources/localization/Localization'
import { formatDateTime, formatNumber } from '../../../../Util/string'
import TracksPackage from './TrackPackages'
import './styles.scss'
import { IPackage } from 'src/Interface/PackageInterface'
import { ColumnType } from 'antd/lib/table'
import { useMidmile } from 'src/hooks/fetch/useMidmile'
import ShipmentDetailInterface from 'src/Interface/ShipmentDetailInterface'

interface OrderItemsProps {
  packages: IPackage[]
  loading: boolean
  waybillFromShipment: any
  provider: ShipmentDetailInterface['provider'] | undefined
}

const Packages: React.FC<OrderItemsProps> = (props) => {
  const [statuses, setStatuses] = useState<PackageStatusInterface[]>([])
  const { packages, loading, waybillFromShipment, provider } = props
  const [expandedRows, setExpandedRows] = useState<Array<any>>([])
  const [packageTransform, setPackageTransform] = useState<any>([])
  const { midmileLogoMapping, midmileNameMapping } = useMidmile(provider)

  useEffect(() => {
    getStatuses()
  }, [])

  const getStatuses = () => {
    CategoryApi.getPackageStatuses()
      .then((res) => {
        setStatuses(lodash.sortBy(res.data, 'position'))
      })
      .catch((err) => {})
  }
  useEffect(() => {
    let packageTransform = []
    if (waybillFromShipment?.length) {
      let waybillCodes = map(sortBy(waybillFromShipment, ['createdAt']).reverse(), 'code')
      for (const waybillCode of waybillCodes) {
        const curWaybill = waybillFromShipment.find((waybill: any) => waybill.code === waybillCode)

        const packageGrouped = packages.filter((pack: any) => pack.waybillCode === waybillCode)

        const packageUpdated = {
          packages: packageGrouped,
          total: packageGrouped?.length,
          waybillCode,
          createdAt: curWaybill?.createdAt,
        }
        packageTransform.push(packageUpdated)
      }
    }
    setPackageTransform(packageTransform)
  }, [packages, waybillFromShipment])

  const packageInfoText = 'text-500 text-reg white-nowrap font-size-14'

  const columnsPackages: ColumnType<any>[] = [
    {
      title: <span className="robotoregular font-size-12 line-h-22 txt-color-gray">#</span>,
      key: 'index',
      width: '1%',
      render: (text: string, record: any, index: number) => <span className={packageInfoText}>{++index}</span>,
    },
    {
      title: (
        <span className="mg-l-4 robotoregular font-size-12 line-h-22 txt-color-gray">{trans('package.code')}</span>
      ),
      key: 'code',
      dataIndex: 'code',
      width: '12%',
      render: (code: string) => <span className={packageInfoText}>{code}</span>,
    },
    {
      title: (
        <span className="flex flex-nowrap items-center mg-l-4 robotoregular font-size-12 line-h-22 txt-color-gray">
          <span className={`mg-r-4 white-nowrap ${packageInfoText}`}>{trans('package.weight')}</span>
          <span className={`text text-gray white-nowrap`}>{trans('package.noteForWeight')}</span>
        </span>
      ),
      key: 'info',
      width: '28%',
      render: (record: any) => {
        const emptyTxt = '---'

        const actualWeight = lodash.isNull(lodash.get(record, 'actualWeight'))
          ? emptyTxt
          : lodash.get(record, 'actualWeight')

        const netWeight = lodash.isNull(lodash.get(record, 'netWeight')) ? emptyTxt : lodash.get(record, 'netWeight')

        const packagingWeight = lodash.isNull(lodash.get(record, 'packagingWeight'))
          ? emptyTxt
          : lodash.get(record, 'packagingWeight')

        const dimensionalWeight = lodash.isNull(lodash.get(record, 'dimensionalWeight'))
          ? emptyTxt
          : lodash.get(record, 'dimensionalWeight')

        return (
          <span className={packageInfoText}>
            {actualWeight === 0 ? '---' : <span>{actualWeight}kg</span>} /{' '}
            {netWeight === 0 ? '---' : <span>{netWeight}kg</span>} /{' '}
            {packagingWeight === 0 ? '---' : <span>{packagingWeight}kg</span>} /{' '}
            {dimensionalWeight === 0 ? '---' : <span>{dimensionalWeight}kg</span>}
          </span>
        )
      },
    },
    {
      title: (
        <span className="flex flex-nowrap items-center mg-l-4 robotoregular font-size-12 line-h-22 txt-color-gray">
          <span className="mg-r-4 white-nowrap">{trans('package.details')}</span>
          <span className="text text-gray">{trans('package.noteForDetail')}</span>
        </span>
      ),
      key: 'details',
      width: '14%',
      render: (record: any) => {
        const emptyTxt = '---'
        const length = lodash.isNull(lodash.get(record, 'length')) ? emptyTxt : lodash.get(record, 'length', emptyTxt)
        const width = lodash.isNull(lodash.get(record, 'width')) ? emptyTxt : lodash.get(record, 'width', emptyTxt)
        const height = lodash.isNull(lodash.get(record, 'height')) ? emptyTxt : lodash.get(record, 'height', emptyTxt)
        return (
          <span className={packageInfoText}>{`${formatNumber(length)} x ${formatNumber(width)} x ${formatNumber(
            height
          )} (cm)`}</span>
        )
      },
    },
    {
      title: <span className="txt-color-gray">Vận chuyển Midmile</span>,
      key: 'midMileParcel',
      dataIndex: 'midMileParcel',
      render: (midMileParcel: IPackage['midMileParcel']) =>
        midMileParcel ? (
          <>
            <Avatar src={midmileLogoMapping[midMileParcel.courier]} size={24} className="inline-block" />{' '}
            <span>{midmileNameMapping[midMileParcel.courier]}</span>
          </>
        ) : (
          '---'
        ),
    },
    {
      title: (
        <span className="mg-l-4 robotoregular font-size-12 line-h-22 txt-color-gray">{trans('package.status')}</span>
      ),
      key: 'notice',
      dataIndex: 'status',
      width: '12%',
      render: (status: string) => {
        const statusObj = statuses.find(({ code }) => code === status)
        const statusName = lodash.get(statusObj, 'name', '---')
        return (
          <span
            className={`text white-nowrap text-white rad-25 py-3 px-12`}
            style={{
              backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
                ? '#09B2AA'
                : lodash.get(statusObj, 'color'),
            }}
          >
            {statusName}
          </span>
        )
      },
    },
    {
      title: (
        <span className="mg-l-4 font-size-14 robotoregular font-size-12 line-h-22 txt-color-gray">
          {trans('package.lastUpdateTime')}
        </span>
      ),
      key: 'notice',
      dataIndex: 'lastStatusTime',
      render: (lastStatusTime: any) => (
        <span className={packageInfoText}>
          {lodash.isNull(lastStatusTime) ? trans('orderDetail.undefined') : formatDateTime(lastStatusTime)}
        </span>
      ),
    },
  ]

  const columns: ColumnType<any>[] = [
    {
      title: <>Mã vận đơn ({packageTransform.length})</>,
      key: 'code',
      dataIndex: 'waybillCode',
    },
    {
      title: 'Tổng số kiện',
      key: 'total',
      dataIndex: 'total',
      align: 'right',
      render: (total: number) => formatNumber(total),
    },
    {
      title: 'Ngày thêm',
      dataIndex: 'createdAt',
      align: 'right',
      render: (createdAt: any, record: any) => {
        return (
          <span className="font-size-14 line-h-22 txt-color-black robotoregular">
            {createdAt ? formatDateTime(createdAt) : 'Chưa xác định'}
          </span>
        )
      },
    },
  ]

  const total = packages.length

  return (
    <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]} className={`main-table`}>
      <Table
        rowKey={(record: any) => record.waybillCode}
        columns={columns}
        dataSource={packageTransform}
        locale={{ emptyText: <EmptyData text={trans('package.noData')} /> }}
        loading={loading}
        className={`package-table package-list table-expandable table-expandable_has-milestone`}
        pagination={false}
        size="small"
        scroll={{}}
        onRow={(record, index) => {
          return { ...record, total, index, statuses }
        }}
        expandable={{
          columnWidth: 0,
          expandRowByClick: true,
          expandedRowRender: (record) => {
            const components = { body: { row: TracksPackage } }
            return record.packages.length > 0 ? (
              <Table
                components={components}
                rowKey={(record: any) => record.id}
                columns={columnsPackages}
                dataSource={record.packages}
                loading={loading}
                className={`table-package-list package-list-waybill`}
                pagination={false}
                size="small"
                onRow={(record, index) => {
                  return { ...record, total, index, statuses }
                }}
              />
            ) : (
              <EmptyData text={trans('package.noData')} /> // <EmptyDataFilter description={t('packages.empty')} />
            )
          },
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <i
                className="far fa-angle-down txt-color-orange2 w-8 line-h-22 cursor-pointer"
                onClick={(e) => onExpand(record, e)}
              ></i>
            ) : (
              <i
                className="far fa-angle-right txt-color-orange2 w-8 line-h-22 cursor-pointer"
                onClick={(e) => onExpand(record, e)}
              ></i>
            ),
          onExpandedRowsChange: (expandedRows) => {
            setExpandedRows([...expandedRows])
          },
        }}
        rowClassName={(record) =>
          lodash.includes(expandedRows, record.waybillCode)
            ? 'active-row cursor-pointer bg-color-pink '
            : 'data-row cursor-pointer'
        }
      />
    </SkeletonTable>
  )
}
export default Packages

import { Avatar, Col, Dropdown, MenuProps, Row, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import lodash from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import ConnectionBar from '../../../Component/ConnectionBar'
import {
  PERMISSION_SHIPMENT_LOG_VIEW,
  SHIPMENT_UPDATE_MIDMILE_PARTNER,
  SHIPMENT_VIEW_MIDMILE_PARTNER,
  SHIPMENT_VIEW_PROVIDER_DETAIL,
} from '../../../Config/permission'
import ProviderInterface from '../../../Interface/ProviderInterface'
import ShipmentDetailInterface from '../../../Interface/ShipmentDetailInterface'
import StatusInterface from '../../../Interface/StatusInterface'
import SecurityService from '../../../Util/SecurityService'
import useWindowDimensions from '../../../Util/dimention'
import { formatMoneyByUnit } from '../../../Util/string'
import { GetOrderLinkType } from '../../../hooks/fetch/useProvider'
import IMAGE_DEFAULT from '../../../resources/images/empty-image.jpg'
import { trans } from '../../../resources/localization/Localization'
import Logs from './Logs'
import { useMidmile } from '../../../hooks/fetch/useMidmile'
import { MidmileView } from '../../../Component/views/MidmileView'
import { ShipmentsApi } from '../../../Api/ShipmentsApi'
import { NotificationCommon } from '../../../Component/basic/NotificationCommon'
import { AxiosResponse } from 'axios'

interface HeadingProps {
  statuses: StatusInterface[]
  details?: ShipmentDetailInterface
  providers: ProviderInterface[]
  currencyOfProvider: string
  loading: boolean
  providerNameMapping: any
  getDetails: () => void
  setDetails: (details: ShipmentDetailInterface) => void
  getOrderShipmentLink: GetOrderLinkType
}

const Heading: React.FC<HeadingProps> = (props) => {
  const {
    statuses,
    details,
    setDetails,
    providers,
    currencyOfProvider,
    loading,
    providerNameMapping,
    getOrderShipmentLink,
  } = props
  const [provider, setProvider] = useState<ProviderInterface>()
  const [statusObj, setStatusObj] = useState<StatusInterface>()
  const [visibleLogModal, setVisibleLogModal] = useState(false)
  const [metadata, setMetadata] = useState<any>()
  const [currency, setCurrency] = useState('')
  const { midmileList } = useMidmile(details?.provider)
  const midmileCode = details?.midMileViews?.[0]?.code

  useEffect(() => {
    const provider = providers?.find(({ code }: ProviderInterface) => code === details?.provider)
    const statusObj = statuses?.find(({ code }: StatusInterface) => code === details?.status)
    setStatusObj(statusObj)
    setProvider(provider)
    setCurrency(currencyOfProvider)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.provider, details?.status, providers, statuses])
  const { width } = useWindowDimensions()

  const isCanViewMidmile = useMemo(() => {
    return SecurityService.can(SHIPMENT_VIEW_MIDMILE_PARTNER)
  }, [])

  const isCanChangeMidmile = useMemo(() => {
    return SecurityService.can(SHIPMENT_UPDATE_MIDMILE_PARTNER)
  }, [])

  const renderProviderLink = () => {
    return (
      SecurityService.can(SHIPMENT_VIEW_PROVIDER_DETAIL) &&
      details && (
        <a
          onClick={(e) => {
            e.stopPropagation()
          }}
          href={getOrderShipmentLink(details.provider, details.providerShipmentCode)}
          style={{ whiteSpace: 'nowrap', fontSize: 14 }}
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-regular fa-arrow-up-right-from-square"></i> Đơn đối tác
        </a>
      )
    )
  }

  const onChangeMidmile = ({ selectedKeys }: { selectedKeys: string[] }) => {
    if (!details) return

    ShipmentsApi.updateMidmile(details.code, [...selectedKeys])
      .then((res: AxiosResponse<ShipmentDetailInterface>) => {
        NotificationCommon.success({ message: 'Thay đổi đối tác vận chuyển midmile thành công' })
        setDetails(res.data)
      })
      .catch(NotificationCommon.notifyHttpError)
  }

  const items: MenuProps['items'] = midmileList.map((midmile) => ({
    key: midmile.code,
    label: <MidmileView midMileView={[midmile]} />,
  }))

  const renderMidMile = () => {
    if (!isCanViewMidmile) return null

    return (
      <div className="flex gap-4 items-center">
        <span className="txt-color-gray mg-r-3 line-h-28  text-400 font-size-14">Vận chuyển Midmile:</span>
        {isCanChangeMidmile ? (
          <Dropdown
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: midmileCode ? [midmileCode] : undefined,
              onSelect: onChangeMidmile,
            }}
            trigger={['click']}
          >
            <span className="inline-flex items-center gap-4 cursor-pointer">
              <MidmileView midMileView={details?.midMileViews} />{' '}
              <i className="fa-regular fa-pen-to-square text-primary"></i>
            </span>
          </Dropdown>
        ) : (
          <span>
            <MidmileView midMileView={details?.midMileViews} />
          </span>
        )}
      </div>
    )
  }

  return width < 768 ? (
    <>
      <div className="shipment-heading grid">
        <div className="flex align-items-center">
          <Avatar
            size={60}
            shape="square"
            className="mg-r-16 rad-6 shipment-heading__basic-image flex-shrink-none"
            src={details?.image ? lodash.get(details, 'image', '') : IMAGE_DEFAULT}
          />
          <div>
            <Paragraph
              copyable={{
                text: lodash.get(details, 'providerShipmentCode', '---'),
                icon: [
                  <Tooltip key="ffjgfd" title="Copy" color="#CD5E77">
                    <i className="far fa-copy line-h-14"></i>
                  </Tooltip>,
                  <Tooltip key="jddsjjh" title="Copied" color="#CD5E77">
                    <i className="far fa-check fsz-12 line-h-14"></i>
                  </Tooltip>,
                ],
                tooltips: [false, false],
              }}
              className="mg-bt-0 text-violet text-md text-500 line-h-28 text-nowrap"
            >
              {`#${lodash.get(details, 'providerShipmentCode', '---')}`}
            </Paragraph>
            <div className="mb-1">
              <span
                className="line-h-22 rad-25 txt-color-white px-16 py-4 txt-capitalize fsz-14  robotomedium "
                style={{ backgroundColor: `${statusObj?.color}` }}
              >
                {statusObj?.name}
              </span>
            </div>
          </div>
        </div>
        <div className="mg-t-12">
          <div>
            {renderProviderLink()}
            {renderMidMile()}
          </div>
          <div className="grid content-center">
            <div className=" flex items-center">
              {!loading ? (
                <span className="txt-color-gray mg-r-3 line-h-28  text-400 font-size-14">
                  {`${trans('shipments.costTotal')}:`}
                </span>
              ) : (
                <Skeleton width={60} height={26} className="mg-r-3" />
              )}
              {!loading ? (
                <span className="text-500 line-h-28 font-size-14">
                  {formatMoneyByUnit(
                    lodash.identity(details?.totalFee) ? details?.totalFee : '---',
                    currencyOfProvider
                  )}
                </span>
              ) : (
                <Skeleton width={80} height={26} />
              )}
            </div>
            <div className="">
              <div className=" flex items-center">
                {!loading ? (
                  <span className="txt-color-gray mg-r-3 line-h-28  text-400 font-size-14">
                    {`${trans('shipments.totalAmount')}:`}
                  </span>
                ) : (
                  <Skeleton width={60} height={26} className="mg-r-3" />
                )}
                {!loading ? (
                  <span className="text-500 line-h-28 font-size-14">
                    {formatMoneyByUnit(
                      lodash.identity(details?.totalValue) ? details?.totalValue : '---',
                      details?.currency
                    )}
                  </span>
                ) : (
                  <Skeleton width={80} height={26} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mg-t-8 mg-bt-6">
          {!loading ? (
            <ConnectionBar
              logo={provider?.logo}
              username={details?.providerUsername}
              providerName={details ? providerNameMapping[details.provider] || details.provider : undefined}
              sizeImg={16}
              className="text-reg txt-color-gray"
              divider="|"
              inFilter={false}
              isRedirect
            />
          ) : (
            <Skeleton width={205} height={26} />
          )}
        </div>

        {SecurityService.can(PERMISSION_SHIPMENT_LOG_VIEW) && (
          <>
            <div
              className="shipment-heading-logs font-size-14 text-500 cursor-pointer mg-l-4"
              onClick={() => {
                setVisibleLogModal(true)
                setMetadata({ page: 0, size: 15 })
              }}
            >
              {!loading ? (
                <i className="far fa-file-alt line-h-12 mg-r-4" />
              ) : (
                <Skeleton width={14} height={14} className="mg-r-4" />
              )}
              <span>{!loading ? trans('shipment.log') : <Skeleton width={113} height={20} />}</span>
            </div>
            <Logs
              visible={visibleLogModal}
              visibleHandler={(val: boolean) => setVisibleLogModal(val)}
              currency={currency}
              metadata={metadata}
              handleMetadata={(obj: any) => setMetadata(obj)}
            />
          </>
        )}
      </div>
    </>
  ) : (
    <div className="shipment-heading width100pc ">
      {!loading ? (
        <Avatar
          size={60}
          shape="square"
          className="mg-r-16 rad-6 shipment-heading__basic-image flex-shrink-none"
          src={details?.image ? lodash.get(details, 'image', '') : IMAGE_DEFAULT}
        />
      ) : (
        <Skeleton width={60} height={60} className="mg-r-8" />
      )}
      <Row gutter={width < 1024 ? 30 : [48, 16]} className="items-center flex-grow">
        <Col md={5} xl={4} flex="1 0 25%">
          <div className="grid content-center">
            {!loading ? (
              <div className="flex">
                <Paragraph
                  copyable={{
                    text: lodash.get(details, 'providerShipmentCode', '---'),
                    icon: [
                      <Tooltip key={'gfggf'} title="Copy" color="#CD5E77">
                        <i className="far fa-copy line-h-14"></i>
                      </Tooltip>,
                      <Tooltip key={'jhdd'} title="Copied" color="#CD5E77">
                        <i className="far fa-check fsz-12 line-h-14"></i>
                      </Tooltip>,
                    ],
                    tooltips: [false, false],
                  }}
                  className="mg-bt-0 text-violet text-md text-500 line-h-28 text-nowrap"
                >
                  {`#${lodash.get(details, 'providerShipmentCode', '---')}`}
                </Paragraph>
              </div>
            ) : (
              <Skeleton width={90} height={28} className="mg-r-4" />
            )}

            <div className="mg-t-4">
              <span
                className="line-h-22 rad-25 txt-color-white px-16 py-4 txt-capitalize fsz-14  robotomedium "
                style={{ backgroundColor: `${statusObj?.color}` }}
              >
                {statusObj?.name}
              </span>
            </div>
          </div>
        </Col>

        <Col md={5} xl={4}>
          <div className="grid content-center">
            <div className="mg-bt-8">
              {!loading ? (
                <ConnectionBar
                  logo={provider?.logo}
                  username={details?.providerUsername}
                  providerName={details ? providerNameMapping[details.provider] || details.provider : undefined}
                  sizeImg={20}
                  className="text-reg txt-color-gray"
                  divider="|"
                  inFilter={false}
                  isRedirect
                  isShowProviderTitle
                />
              ) : (
                <Skeleton width={205} height={26} />
              )}
            </div>
            {SecurityService.can(PERMISSION_SHIPMENT_LOG_VIEW) && (
              <>
                <div
                  className="shipment-heading-logs font-size-14 text-500 cursor-pointer mg-l-4"
                  onClick={() => {
                    setVisibleLogModal(true)
                    setMetadata({ page: 0, size: 15 })
                  }}
                >
                  {!loading ? (
                    <i className="far fa-file-alt line-h-12 mg-r-4" />
                  ) : (
                    <Skeleton width={14} height={14} className="mg-r-4" />
                  )}
                  <span>{!loading ? trans('shipment.log') : <Skeleton width={113} height={20} />}</span>
                </div>
                <Logs
                  visible={visibleLogModal}
                  visibleHandler={(val: boolean) => setVisibleLogModal(val)}
                  currency={currency}
                  metadata={metadata}
                  handleMetadata={(obj: any) => setMetadata(obj)}
                />
              </>
            )}
          </div>
        </Col>

        <Col md={5} xl={4}>
          <div className="grid content-center">
            <div className=" flex items-center">
              {!loading ? (
                <span className="txt-color-gray mg-r-3 line-h-28  text-400 font-size-14">
                  {`${trans('shipments.costTotal')}:`}
                </span>
              ) : (
                <Skeleton width={60} height={26} className="mg-r-3" />
              )}
              {!loading ? (
                <span className="text-500 line-h-28 font-size-14">
                  {formatMoneyByUnit(
                    lodash.identity(details?.totalFee) ? details?.totalFee : '---',
                    currencyOfProvider
                  )}
                </span>
              ) : (
                <Skeleton width={80} height={26} />
              )}
            </div>
            <div className="mg-t-4">
              <div className=" flex items-center">
                {!loading ? (
                  <span className="txt-color-gray mg-r-3 line-h-28  text-400 font-size-14">
                    {`${trans('shipments.totalAmount')}:`}
                  </span>
                ) : (
                  <Skeleton width={60} height={26} className="mg-r-3" />
                )}
                {!loading ? (
                  <span className="text-500 line-h-28 font-size-14">
                    {formatMoneyByUnit(
                      lodash.identity(details?.totalValue) ? details?.totalValue : '---',
                      details?.currency
                    )}
                  </span>
                ) : (
                  <Skeleton width={80} height={26} />
                )}
              </div>
            </div>
          </div>
        </Col>

        <Col md={9} xl={12}>
          <div>
            {renderProviderLink()}
            {renderMidMile()}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Heading

import lodash from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CategoryApi } from '../../Api/CategoryApi'
import MarketPalaceCommand from '../../Interface/MarketPalaceInterface'
import StatusCommand from '../../Interface/StatusInterface'
import DefaultLayout from '../../Component/Layout/Default'
import { getCleanFormFilter } from '../../Util/string'
import Filter from './Filter'
import { trans } from '../../resources/localization/Localization'
import '../../resources/scss/screens/_shipments.scss'
import ShipmentItems from './Data'
import { ShipmentsApi } from '../../Api/ShipmentsApi'
import { ShipmentsCriteria } from '../../Criteria/ShipmentsCriteria'
import { useProvider } from '../../hooks/fetch/useProvider'

const Shipments = (props: any) => {
  const [shipments, setShipments] = useState<Array<ShipmentsCriteria>>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(Number(searchParams.get('page')))
  const [pageSize, setPagesize] = useState(Number(searchParams.get('size')))
  const {providers, getOrderShipmentLink} = useProvider()
  const [shipmentStatus, setShipmentStatus] = useState<Array<StatusCommand>>([])
  const [marketPalaces, setMarketplaces] = useState<Array<MarketPalaceCommand>>([])
  const [filter, setFilter] = useState<ShipmentsCriteria>({})
  const [loadingStatuses, setLoadingStatuses] = useState(false)
  const [loadingMarketPalaces, setLoadingMarketPalaces] = useState(false)
  const [isSkeleton, setIsSkeleton] = useState(true)

  const getShipments = useCallback(async () => {
    const merchantName = searchParams.get('merchantName')
    const provider = searchParams.get('provider')
    const providerUsername = searchParams.get('providerUsername')
    const code = searchParams.get('code')
    const waybill = searchParams.get('waybill')
    const originalReceipt = searchParams.get('originalReceipt')
    const username = searchParams.get('username')
    const statuses = searchParams.get('statuses')
    const timestampTo = searchParams.get('timestampTo')
    const timestampFrom = searchParams.get('timestampFrom')
    const page = searchParams.get('page')
    const size = searchParams.get('size')
    const orderBy = searchParams.get('orderBy')
    const temp = {
      page,
      size,
      code,
      waybill,
      originalReceipt,
      merchantName,
      username,
      statuses,
      provider,
      providerUsername,
      timestampTo,
      timestampFrom,
      orderBy,
    }
    setFilter((prevState) => ({ ...prevState, ...getCleanFormFilter(temp) }))
    setTimeout(() => setIsSkeleton(false), 1000)
    try {
      const response = await ShipmentsApi.filter(getCleanFormFilter(temp))
      const currentPage = lodash.get(response, 'headers.x-page-number')
      const total = lodash.get(response, 'headers.x-total-count')
      setShipments(response.data)
      setPage(parseInt(currentPage) + 1)
      setTotal(parseInt(total))
      setPagesize(parseInt(lodash.get(response, 'headers.x-page-size')))
      setTimeout(() => setIsSkeleton(false), 1000)
    } catch (err) {
      setTimeout(() => setIsSkeleton(false), 1000)
    }
  }, [searchParams])

  const getMarketPalaces = useCallback(async () => {
    setLoadingMarketPalaces(true)
    try {
      const response = await CategoryApi.getMarketPalaces()
      setMarketplaces(response.data)
      setLoadingMarketPalaces(false)
    } catch (err) {
      setLoadingMarketPalaces(false)
    }
  }, [])

  const getStatuses = useCallback(async () => {
    setLoadingStatuses(true)
    try {
      const response = await CategoryApi.getShipmentStatuses()
      setShipmentStatus(response.data)
      setLoadingStatuses(false)
    } catch (err) {
      setLoadingStatuses(false)
    }
  }, [])

  useEffect(() => {
    getShipments()
  }, [getShipments])

  useEffect(() => {
    getStatuses()
    getMarketPalaces()
  }, [getStatuses, getMarketPalaces])

  const handleFilter = (params: any) => {
    const orderBy = searchParams.get('orderBy') || 'desc'
    setFilter({ orderBy, ...params })
    setSearchParams({ orderBy, ...params })
  }

  return (
    <DefaultLayout title={trans('orders.list')} {...props}>
      <Filter
        handleFilter={handleFilter}
        statuses={shipmentStatus}
        marketPalaces={marketPalaces}
        setFilter={(obj: ShipmentsCriteria) => setFilter(obj)}
        filter={filter}
        loadingStatuses={loadingStatuses}
        loadingMarkets={loadingMarketPalaces}
        providers={providers}
      />
      <ShipmentItems
        isSkeleton={isSkeleton}
        total={total}
        page={page}
        size={pageSize}
        orderBy={searchParams.get('orderBy') as any}
        data={shipments}
        providers={providers}
        statuses={shipmentStatus}
        handleFilter={handleFilter}
        setPage={(val: number) => setPage(val)}
        setPageSize={(val: number) => setPagesize(val)}
        filter={filter}
        getOrderShipmentLink={getOrderShipmentLink}
      />
    </DefaultLayout>
  )
}

export default Shipments

import { Steps } from 'antd'
import lodash from 'lodash'
import { GetComponentProps } from 'rc-table/lib/interface'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { trans } from '../../../../resources/localization/Localization'
import { ShipmentsApi } from '../../../../Api/ShipmentsApi'
import { formatDateTime } from '../../../../Util/string'

const { Step } = Steps
interface TracksPackageProps extends GetComponentProps<any> {
	total: number
	statuses: any
	code: string
	children: any
}
const TracksPackage: React.FC<TracksPackageProps> = (record) => {
	// const { t } = useTranslation()
	const { code } = useParams()
	const [milestones, setMilestones] = useState([])

	useEffect(() => {
		const fetchMilestones = async () => {
			try {
				if (record.code) {
					const res = await ShipmentsApi.getMilestoneOfShipments(code!, record?.code)
					setMilestones(res.data)
				}
				return
			} catch (err) {
			}
		}
		fetchMilestones()
	}, [code, record?.code])

	const customDot = (dot: any, { status, index }: any) => {
		return <i className='far fa-dot-circle text-sm' />
	}

	const CustomDescription = ({ code }: { code: string }) => {
		const historyTrackByPosition = milestones.filter(({ status }: any) => status === code)
		const classes = 'flex flex-col items-start justify-start'
		return (
			<span className={`${classes}`}>
				{historyTrackByPosition.length > 0 ? (
					historyTrackByPosition.map(({ timestamp, handlingTime }: any, idx: number) => {
						const timestampRendered = lodash.isNull(timestamp)
							? trans('orderDetail.undefined')
							: formatDateTime(timestamp)
						const handlingTimeRendered = lodash.isNull(handlingTime)
							? trans('orderDetail.undefined')
							: `${handlingTime} ngày`
						return (
							<span className={`${classes} mg-bt-4`} key={idx}>
								<span className='txt-color-gray4'>{timestampRendered}</span>
								<span className='txt-color-gray4'>{`(${handlingTimeRendered})`}</span>
							</span>
						)
					})
				) : (
					<span className='txt-color-gray2'>{`${trans('orderDetail.undefined')}`}</span>
				)}
			</span>
		)
	}
	const codes = lodash.uniq(lodash.map(milestones, 'status'))
	let positions: Number[] = []
	for (let index = 0; index < codes.length; index++) {
		const status = record.statuses.find(({ code }: any) => code === codes[index])
		positions.push(lodash.get(status, 'position')!)
	}
	const positionMax = lodash.max(positions)
	const lastIndex = lodash.get(record, 'total')
	const curIndex = lodash.get(record, 'index')

	return (
		<>
			<tr className={`package-data `}>{record.children}</tr>
			<tr className={`bd-bt-none package-timeline ${!record.total ? 'dpl-none ' : ''}`}>
				<td colSpan={7} className='td-table-cell'>
					<div className={`package-timeline  ${curIndex === lastIndex  ? 'pd-bt-0' : 'package-timeline-tabs'} pd-t-12`}>
						<Steps progressDot={customDot} responsive={false}>
							{milestones &&
								milestones.length > 0 &&
								record.statuses.map(({ name, code, id, position }: any) => {
									const indexOfStatus = milestones.findIndex(({ status }: any) => code === status)
									const status = indexOfStatus > -1 ? 'finish' : 'wait'
									const txtClasses = status === 'finish' ? 'txt-color-black' : 'txt-color-gray2'
									return (
										<Step
											status={status}
											key={id}
											className={
												position > positionMax!
													? 'tail-none'
													: position === positionMax
													? 'tail-none last-tail'
													: 'tail'
											}
											title={
												<span
													className={`txt-left robotomedium fsz-12 line-h-20 ${txtClasses}`}
												>
													{name}
												</span>
											}
											description={<CustomDescription code={code} />}
										/>
									)
								})}
						</Steps>
					</div>
				</td>
			</tr>
		</>
	)
}

export default TracksPackage


import { FC } from 'react'
import { ITransportFee } from 'src/Interface/FeeInterface'
import { formatMoneyByUnit } from 'src/Util/string'
import Skeleton from 'react-loading-skeleton'

interface IProps {
  estimatedFee: ITransportFee[]
  loading?: boolean
  currency: string
}

export const EstimateFee: FC<IProps> = ({ estimatedFee, loading, currency }) => {
  return (
    <>
      {estimatedFee.map((fee, idx) => (
        <div className="flex justify-between fee-items mg-l-16" key={fee.code}>
          <span className="flex py-4 label align-items-center txt-color-gray3">
            {fee.name}
            {<span className="label-notice txt-size-h8 txt-color-gray2 mg-l-6">{`(2.${idx + 1})`}</span>}
          </span>

          <span>{!loading ? formatMoneyByUnit(fee.fee, currency) : <Skeleton width={80} />}</span>
        </div>
      ))}
    </>
  )
}

import { Avatar, Select, Skeleton, Table, notification } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import lodash from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CategoryApi } from '../../../Api/CategoryApi'
import { SupplierApi } from '../../../Api/SupplierApi'
import ConnectionBar from '../../../Component/ConnectionBar'
import EmptyData from '../../../Component/EmptyData'
import DefaultLayout from '../../../Component/Layout/Default'
import ConnectionOfCustomerInterface from '../../../Interface/ConnectionOfCustomerInterface'
import ConnectionStatusInterface from '../../../Interface/ConnectionStatusInterface'
import ProviderInterface from '../../../Interface/ProviderInterface'
import { trans } from '../../../resources/localization/Localization'
import { formatDateTime, formatMoneyByUnit } from '../../../Util/string'
import ModalDeposit from './ModalDeposit'
import { DepositApi } from '../../../Api/DepositApi'
import SecurityService from '../../../Util/SecurityService'
import { CUSTOMER_DEPOSIT_CREATE, PERMISSION_TRANSACTION_HISTORY_VIEW } from '../../../Config/permission'
import { TransactionHistoryModal } from './TransactionHistoryModal'
import { ColumnsType } from 'antd/lib/table'
const { Option } = Select
interface OrderDetailProps { }
const CustomerDetail: React.FC<OrderDetailProps> = (props) => {
	const { username } = useParams()
	const { xs } = useBreakpoint()
	const [customerDetail, setCustomerDetail] = useState<any>()
	const [providers, setProviders] = useState<any>([])
	const [providerCode, setProviderCode] = useState('')
	const [connections, setConnections] = useState<ConnectionOfCustomerInterface[]>([])
	const [addressDefault, setAddressDefault] = useState('')
	const [loading, setLoading] = useState(false)
	const [connectionStatuses, setConnectionStatuses] = useState<ConnectionStatusInterface[]>([])
	const [currency, setCurrency] = useState('')
	const [loadingDetails, setLoadingDetails] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isLoading, setisLoading] = useState(false)
	const [balance, setBalance] = useState('');


	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const [idForShowTransactionHistory, setIdForShowTransactionHistory] = useState<{ id: string; provider: string }>()

	const fetchCustomerDetail = useCallback(async () => {
		setLoadingDetails(true)
		try {
			const res = await SupplierApi.customerDetail(username)
			setCustomerDetail(res.data)
			setLoadingDetails(false)
		} catch (err) {
			setLoadingDetails(false)
		}
	}, [username])

	const fetchProviders = useCallback(async () => {
		try {
			const res = await CategoryApi.getProviders()
			setProviders(res.data)
		} catch (err) { }
	}, [])

	const fetchConnectionOfCustomers = useCallback(async () => {
		setLoading(true)
		try {
			await SupplierApi.getConnectionByCustomer(username!, providerCode).then(async (res) => {
				let balance = await SupplierApi.getBalanceOfConnection(username!, res.data[0].id)
				setBalance(balance.data.balance)
				setConnections(res.data)
				setLoading(false)
			})

		} catch (err) {
			setLoading(false)
		}
	}, [providerCode, username])

	const fetchDefaultAddressForShip = useCallback(async () => {
		try {
			const res = await SupplierApi.getDefaultAddress(username!)
			setAddressDefault(`${res.data?.address1} ${res.data?.display}`)
		} catch (err) {
			setAddressDefault('---')
		}
	}, [username])

	//   useEffect(() => {
	// 	const fetchBalanceOfConnection = async () => {
	// 		try {
	// 			const res = await SupplierApi.getBalanceOfConnection(username!, id)
	// 			setBalance(res.data.balance)
	// 		}catch(err) {}
	// 	}
	// 	fetchBalanceOfConnection()

	// }, [id, username,isLoading])

	const fetchConnectionStatuses = useCallback(async () => {
		try {
			const res = await CategoryApi.getConnectionStatuses()
			setConnectionStatuses(res.data)
		} catch (err) { }
	}, [])

	const handleChangeProvider = (val: string) => {
		setProviderCode(val)
	}

	useEffect(() => {
		fetchProviders()
		fetchDefaultAddressForShip()
		fetchConnectionStatuses()
		fetchConnectionOfCustomers()
	}, [fetchProviders, fetchDefaultAddressForShip, fetchConnectionStatuses, fetchConnectionOfCustomers])

	useEffect(() => {
		fetchCustomerDetail()
	}, [fetchCustomerDetail])

	useEffect(() => {
		if (!providerCode) {
			setProviderCode(providers[0]?.code)
		}
		const provider = providers.find((provider: ProviderInterface) => provider.code === providerCode)
		if (provider?.currency) {
			setCurrency(JSON.parse(provider?.currency).code)
		}
	}, [providerCode, providers])

	const columns: ColumnsType<ConnectionOfCustomerInterface> = [
		{
			title: <span className="text-nowrap">{trans('customer.nameAccount')}</span>,
			width: '15%',
			render: (record: any) => <span className='text-reg'>{lodash.get(record, 'providerUsername', '---')}</span>,
		},
		{
			title: <span className="text-nowrap">{trans('customer.timConnection')}</span>,
			width: '15%',
			render: (record: any) => (
				<span className='text-reg text-nowrap'>{formatDateTime(moment(lodash.get(record, 'timestamp', '---')))}</span>
			),
		},
		{
			title: <span className="text-nowrap">{trans('customer.status')}</span>,
			width: '10%',
			render: (record: any) => {
				const statusObj = connectionStatuses.find((stt: ConnectionStatusInterface) => stt.code === record.status)
				return <span className='px-10 py-4 text-white text bg-color-app-green rad-20 text-nowrap'>{statusObj?.name}</span>
			},
		},
		{
			title: <span className="text-nowrap">{trans('customer.balance')}</span>,
			width: '10%',
			render: (record: any) => {
				// return <BalanceRow isLoading={isLoading} id={record.id} currency={currency} />
				return <span className='py-4 text-reg text-500 text-red rad-20'>{formatMoneyByUnit(balance, currency)}</span>
			},
			align: 'right' as 'right',
		},
		{
			title: <span className="text-nowrap">{trans('customer.provider')}</span>,
			width: '15%',
			render: (record: any) => {
				const provider = lodash.get(record, 'provider')
				return (
					<span className='text-reg'>
						<ConnectionBar logo={provider.logo} sizeImg={20} divider='' inFilter={false} providerName={provider.name} />
					</span>
				)
			},
		},
		{
			title: <span className="text-nowrap">{trans('customer.default')}</span>,
			width: '15%',
			render: (record: any) => {
				return record.isDefaultConnection ? (
					<span className='px-10 py-4 text-white text bg-primary rad-20 text-nowrap'>{trans('connection.isDefault')}</span>
				) : null
			},
		},

	]
	SecurityService.can(CUSTOMER_DEPOSIT_CREATE) && columns.push(
		{

			title: <></>,
			width: '100',
			align: 'right' as 'right',
			render: (record: any) => {
				return <div className='hover:cursor-pointer underline text-sm text-[#CD5E77]' onClick={showModal}><i className="mr-1 fa-regular fa-money-check-pen"></i>{trans('customer.deposit')}</div>
			},
		}
	);

	if (SecurityService.can(PERMISSION_TRANSACTION_HISTORY_VIEW)) {
		columns.push({
			title: <></>,
			width: 100,
			render: (record: any) => {
				return (
					<>
						<div className='hover:cursor-pointer underline text-sm text-[#CD5E77]' onClick={() => setIdForShowTransactionHistory({ id: record.id, provider: record.provider?.code })}>
							Lịch sử GD
						</div>
					</>
				)
			},
		})
	}


	const handleSubmit = (value: any) => {
		let body: any = {}
		let data = connections.find((item: any) => item.username === lodash.get(customerDetail, 'username'))
		const trimmedFormData = {
			amount: value.amount.trim(),
			reason: value.reason.trim(),
			note: value.note.trim()
		};
		body.username = data?.username
		body.providerUsername = data?.providerUsername
		body.provider = data?.provider?.code
		body.amount = trimmedFormData?.amount
		body.reason = trimmedFormData?.reason
		body.note = trimmedFormData?.note
		setisLoading(true)
		DepositApi.deposit(body)
			.then((res) => {
				notification.success({
					key: 'SUCCESS',
					message: trans('deposit.successTitle'),
					description: trans('deposit.success'),
					duration: 3,
				})
				setTimeout(() => {
					fetchCustomerDetail();
					setIsModalOpen(false)
					setisLoading(false)
					fetchConnectionOfCustomers()
				}, 300)
			})
			.catch((err) => {
				const title = err?.response?.data?.title
				if (title === 'amount_invalid') {
					notification.error({
						message: trans('Đã có lỗi xảy ra'),
						description: 'Số tiền cần nạp phải lớn hơn 0',
						duration: 3,
					})
				}


			}).finally(() => {
				setisLoading(false)
			});
	};


	return (
		<DefaultLayout title={trans('customer.detail')} {...props}>
			<div className='flex flex-col customer-detail'>
				<div className='flex box-main box-info gap-30 mg-bt-12'>
					{loadingDetails ? (
						<div className='flex flex-col items-center gap-10 avatar'>
							<Skeleton.Avatar shape='circle' size={160} active />
							<Skeleton.Input active size='small' />
						</div>
					) : (
						<div className='flex flex-col items-center gap-10 avatar'>
							<Avatar shape='circle' size={160} src={customerDetail?.avatar ? customerDetail?.avatar : ''} className='flex-shrink-0' />
							{xs && <div className='flex flex-col'>
								{loadingDetails ? (
									<Skeleton.Input active size='small' />
								) : (
									<span className='text-lg text-700'>{lodash.get(customerDetail, 'name', '---')}</span>
								)}
								{xs && <span className='text-700 text-md'>{`${lodash.get(customerDetail, 'username', '---')} | ${lodash.get(
									customerDetail,
									'code',
									'---'
								)}`}</span>}
							</div>}
							{!xs && <span className='text-700 text-md'>{`${lodash.get(customerDetail, 'username', '---')} | ${lodash.get(
								customerDetail,
								'code',
								'---'
							)}`}</span>}
						</div>
					)}
					<div className='flex flex-col gap-20 flex-basis-60pc'>
						{!xs && <div>
							{loadingDetails ? (
								<Skeleton.Input active size='small' />
							) : (
								<span className='text-lg text-700'>{lodash.get(customerDetail, 'name', '---')}</span>
							)}
						</div>}
						<div className={`flex ${!xs ? 'gap-50' : ''}`}>
							<div className='flex flex-col gap-12'>
								<div className='flex gap-60'>
									{xs && <div className='flex flex-col gap-4'>
										<span className='text-gray text-nowrap'>{`${trans('customers.createdAt')}:`}</span>
										{loadingDetails ? (
											<Skeleton.Input active size='small' block={true} />
										) : (
											<span className='text-reg text-500 text-nowrap'>{customerDetail?.createdAt ? formatDateTime(customerDetail?.createdAt) : '---'}</span>
										)}
									</div>}
									<div className='flex flex-col gap-4'>
										<span className='text-gray text-nowrap'>{`${trans('customer.phone')}:`}</span>
										{loadingDetails ? (
											<Skeleton.Input active size='small' />
										) : (
											<span className='text-reg text-500 text-nowrap'>
												{customerDetail?.mobile ? customerDetail.mobile : '---'}
											</span>
										)}
									</div>
								</div>
								<div className='flex flex-col gap-4'>
									<span className='text-gray text-nowrap'>{`${trans('customer.emailAddress')}:`}</span>
									{loadingDetails ? (
										<Skeleton.Input active size='small' />
									) : (
										<span className='text-reg text-500'>
											{customerDetail?.email ? customerDetail.email : '---'}
										</span>
									)}
								</div>
								{xs && <div className='flex flex-col gap-4'>
									<span className='text-gray text-nowrap'>{`${trans('customer.addressDefaultForReceiveOrder')}:`}</span>
									{loadingDetails ? (
										<Skeleton.Input active size='small' block={true} />
									) : (
										<span className='text-reg text-500'>{addressDefault ? addressDefault : '---'}</span>
									)}
								</div>}
							</div>
							<div className='flex flex-col gap-12 flex-grow-1'>
								{!xs && <div className='flex flex-col gap-4'>
									<span className='text-gray text-nowrap'>{`${trans('customers.createdAt')}:`}</span>
									{loadingDetails ? (
										<Skeleton.Input active size='small' block={true} />
									) : (
										<span className='text-reg text-500'>{customerDetail?.createdAt ? formatDateTime(customerDetail?.createdAt) : '---'}</span>
									)}
								</div>}
								{!xs && <div className='flex flex-col gap-4'>
									<span className='text-gray text-nowrap'>{`${trans('customer.addressDefaultForReceiveOrder')}:`}</span>
									{loadingDetails ? (
										<Skeleton.Input active size='small' block={true} />
									) : (
										<span className='text-reg text-500'>{addressDefault ? addressDefault : '---'}</span>
									)}
								</div>}
							</div>

						</div>
					</div>
				</div>
				<div className='flex flex-col gap-12 box-main'>
					<div className='flex items-center gap-40'>
						<span className='text-md text-500'>{trans('customer.connect')}</span>
						<Select
							value={providerCode}
							// style={{ width: 120 }}
							onChange={handleChangeProvider}
							className='provider-selection'>
							{providers.length > 0 &&
								providers.map((provider: any) => {
									return (
										<Option value={provider.code} key={provider.id}>
											<ConnectionBar
												logo={provider.logo}
												sizeImg={20}
												divider=''
												inFilter={false}
												providerName={provider.name}
											/>
										</Option>
									)
								})}
						</Select>
					</div>
					{isModalOpen && <ModalDeposit
						isLoading={isLoading}
						isModalOpen={isModalOpen}
						handleOk={handleOk}
						handleCancel={handleCancel}
						customerDetail={customerDetail}
						handleSubmit={handleSubmit}
					/>}
					<Table
						dataSource={connections}
						columns={columns}
						className='main-table main-table__border-head-none'
						locale={{ emptyText: <EmptyData text={trans('orders.empty')} /> }}
						pagination={false}
						loading={loading}
						scroll={{ x: 'max-content' }}
					/>

				</div>
			</div>

			<TransactionHistoryModal
				id={idForShowTransactionHistory?.id}
				provider={idForShowTransactionHistory?.provider}
				onCancel={() => setIdForShowTransactionHistory(undefined)}
				currency={currency}
			/>
		</DefaultLayout>
	)
}

export default CustomerDetail

import { Pagination } from 'antd'
import React, { useState } from 'react'
import { SHIPMENT_VIEW_PROVIDER_DETAIL } from '../../../Config/permission'
import ProviderInterface from '../../../Interface/ProviderInterface'
import StatusInterface from '../../../Interface/StatusInterface'
import { DATA_TABLE_LAYOUT, DATA_TABLE_LIST } from '../../../Util/Constants'
import SecurityService from '../../../Util/SecurityService'
import { getCleanFormFilter } from '../../../Util/string'
import { GetOrderLinkType } from '../../../hooks/fetch/useProvider'
import DataTable from './DataTable'
import ShipmentList from './List'

interface ShipmentItemsProps {
  data: any
  statuses: Array<StatusInterface>
  page: number
  size: number
  orderBy?: 'asc' | 'desc'
  total: number
  isSkeleton: boolean
  filter: any | {}
  providers: ProviderInterface[]
  handleFilter: (params: any | {}) => void
  setPage: (val: number) => void
  setPageSize: (val: number) => void
  getOrderShipmentLink: GetOrderLinkType
}

const ShipmentItems: React.FC<ShipmentItemsProps> = (props) => {
  const {
    data,
    statuses,
    filter,
    page,
    size,
    total,
    handleFilter,
    providers,
    setPage,
    setPageSize,
    isSkeleton,
    getOrderShipmentLink,
  } = props

  const [typeDataTable, setTypeDataTable] = useState(DATA_TABLE_LIST)

  const onChangePage = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
    handleFilter(
      getCleanFormFilter(
        {
          ...filter,
          page: (page - 1).toString(),
          size: pageSize.toString(),
        },
        [],
        ['pageSize']
      )
    )
  }

  const renderProviderLink = (provider: string, providerShipmentCode: string) => {
    return (
      SecurityService.can(SHIPMENT_VIEW_PROVIDER_DETAIL) && (
        <a
          onClick={e => {
            e.stopPropagation()
          }}
          href={getOrderShipmentLink(provider, providerShipmentCode)}
          style={{ whiteSpace: 'nowrap' }}
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa-regular fa-arrow-up-right-from-square"></i> Đơn đối tác
        </a>
      )
    )
  }

  return (
    <div>
      {typeDataTable === DATA_TABLE_LIST && (
        <ShipmentList
          typeDataTable={typeDataTable}
          isSkeleton={isSkeleton}
          handleTypeTableData={(val: string) => setTypeDataTable(val)}
          total={total}
          statuses={statuses}
          providers={providers}
          data={data}
          renderProviderLink={renderProviderLink}
        />
      )}
      {typeDataTable === DATA_TABLE_LAYOUT && (
        <DataTable
          typeDataTable={typeDataTable}
          isSkeleton={isSkeleton}
          handleTypeTableData={(val: string) => setTypeDataTable(val)}
          total={total}
          statuses={statuses}
          providers={providers}
          data={data}
          renderProviderLink={renderProviderLink}
        />
      )}
      {total > 0 && (
        <div className="flex justify-end mg-t-10">
          <Pagination
            total={total}
            pageSize={size}
            current={page}
            onChange={onChangePage}
            className="shipments_pagination"
            defaultCurrent={0}
            pageSizeOptions={['10', '20', '25', '50', '100']}
            showSizeChanger={true}
          />
        </div>
      )}
    </div>
  )
}

export default ShipmentItems

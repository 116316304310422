import { Avatar, Select, Table, Tooltip } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import lodash from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import EmptyData from '../../Component/EmptyData'
import SkeletonTable from '../../Component/SkeletonTable'
import { OrdersCriteria } from '../../Criteria/OrdersCriteria'
import { ServiceInterface } from '../../Interface/OrderDetailInterface'
import ProviderInterface from '../../Interface/ProviderInterface'
import StatusInterface from '../../Interface/StatusInterface'
import { trans } from '../../resources/localization/Localization'
import { formatDateTime, formatMoneyByUnit, getCleanFormFilter } from '../../Util/string'
import SecurityService from '../../Util/SecurityService'
import { ORDER_VIEW_PROVIDER_DETAIL } from '../../Config/permission'

interface ListProps {
  total: number
  page: number
  size: number
  items: Array<OrdersCriteria>
  providers: Array<ProviderInterface>
  statuses?: Array<StatusInterface>
  filter: OrdersCriteria
  orderBy?: 'asc' | 'desc'
  loading: boolean
  getOrderLink: (provider: string, code: string) => string
  handleFilter: (filter: OrdersCriteria) => void
  setPage: (val: number) => void
  setPageSize: (val: number) => void
}

const List: React.FC<ListProps> = (props) => {
  const {
    items,
    providers,
    statuses,
    page,
    size,
    total,
    orderBy,
    filter,
    getOrderLink,
    handleFilter,
    setPage,
    setPageSize,
    loading,
  } = props

  // const { providerRedirecLinks } = {}

  const columns = [
    {
      key: 'info',
      width: '30%',
      render: (record: any) => {
        const orderedQuantity = lodash.isNil(lodash.get(record, 'orderedQuantity', '---'))
          ? '---'
          : lodash.get(record, 'orderedQuantity', '---')
        const purchasedQuantity = lodash.isNil(lodash.get(record, 'purchasedQuantity', '---'))
          ? '---'
          : lodash.get(record, 'purchasedQuantity', '---')
        const receivedQuantity = lodash.isNil(lodash.get(record, 'receivedQuantity', '---'))
          ? '---'
          : lodash.get(record, 'receivedQuantity', '---')
        const services = lodash.get(record, 'services', [])

        return (
          <div className="orders-data__info flex gap-5">
            <Link to={`/orders/${lodash.get(record, 'providerOrderCode', '---')}`}>
              <Avatar shape="square" className="rad-4" src={lodash.get(record, 'image', '')} size={51} />
            </Link>
            <div className="flex flex-col  gap-5">
              <div className="orders-data__info-text flex gap-12 items-center">
                <Paragraph
                  className="code"
                  copyable={{
                    text: lodash.get(record, 'providerOrderCode', '---'),
                    icon: [
                      <Tooltip title={<span>Copy</span>} color="#CD5E77">
                        <i className="far fa-copy line-h-14 w-14"></i>
                      </Tooltip>,
                      <Tooltip title={<span>Copied</span>} color="#CD5E77">
                        <i className="far fa-check fsz-12 line-h-14 w-14"></i>
                      </Tooltip>,
                    ],
                    tooltips: [false, false],
                  }}
                >
                  <Link to={`/orders/${lodash.get(record, 'providerOrderCode', '---')}`}>{`#${lodash.get(
                    record,
                    'providerOrderCode',
                    '---'
                  )}`}</Link>
                </Paragraph>

                {SecurityService.can(ORDER_VIEW_PROVIDER_DETAIL) && (
                  <a
                    href={getOrderLink(record.provider, record.providerOrderCode)}
                    style={{ whiteSpace: 'nowrap' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa-regular fa-arrow-up-right-from-square"></i> Đơn đối tác
                  </a>
                )}

                <div className="divider" />
                <span className="white-nowrap">{formatDateTime(lodash.get(record, 'createdAt', ''))}</span>
                <Tooltip title={trans('order.timestamp')} color="#CD5E77">
                  <i className="fa-light fa-circle-info pointer" />
                </Tooltip>
                <div className="divider" />
                <span className="white-nowrap">{`${orderedQuantity}/${purchasedQuantity}/${receivedQuantity}`}</span>
                <Tooltip title={trans('order.qtyTooltip')} color="#CD5E77">
                  <i className="fa-light fa-circle-info pointer" />
                </Tooltip>
              </div>
              <div className="flex items-center">
                <span className="text text-gray text-nowrap mg-r-3">{trans('order.services')}:</span>
                {services.length > 0
                  ? services.map((item: ServiceInterface, idx: number) => {
                      let colorService = ''
                      let isShowTooltip
                      if (item.needApprove) {
                        if (item.approved === null || item.approved === undefined) {
                          colorService = 'services-pending'
                          isShowTooltip = true
                        } else if (item.approved === false) {
                          colorService = 'services-reject'
                        }
                      }
                      return isShowTooltip ? (
                        <Tooltip title={'Đang chờ duyệt'} color="#CD5E77" className="">
                          <span className="items-center" key={idx}>
                            <span
                              className={`mg-r-4 text text-nowrap line-h-20 ${colorService} pointer`}
                            >{`${item.name}`}</span>
                            {idx !== services.length - 1 && <span className="mg-r-4">|</span>}
                          </span>
                        </Tooltip>
                      ) : (
                        <span className="items-center" key={idx}>
                          <span className={`mg-r-4 text text-nowrap line-h-20 ${colorService}`}>{`${item.name}`}</span>
                          {idx !== services.length - 1 && <span className="mg-r-4">|</span>}
                        </span>
                      )
                    })
                  : '---'}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      key: 'customer',
      width: '24%',
      render: (record: any) => {
        return (
          <div className="flex flex-col gap-5 flex-nowrap">
            <h3 className="text text-gray mg-bt-0 line-h-22">{trans('orders.customer')}</h3>
            <span className="text-reg">{lodash.get(record, 'username', '---')}</span>
          </div>
        )
      },
    },
    {
      key: 'cash',
      width: '8%',
      render: (record: any) => {
        const providerCode = lodash.get(record, 'provider', '---')
        const provider = providers?.find((x: ProviderInterface) => x.code === providerCode)
        let code
        if (provider) {
          const temp = JSON.parse(lodash.get(provider, 'currency'))
          code = temp.code
        }
        return (
          <div className="flex flex-col items-end  gap-5">
            <h3 className="text text-gray mg-bt-0 line-h-22">{trans('order.cash')}</h3>
            <span className="text-reg text-500 text-violet">
              {formatMoneyByUnit(lodash.get(record, 'exchangeTotalAmount', 0), code)}
            </span>
          </div>
        )
      },
    },
    {
      key: 'provider',
      width: '18%',
      render: (record: any) => {
        const providerCode = lodash.get(record, 'provider', '---')
        const provider = providers?.find((x: ProviderInterface) => x.code === providerCode)
        return (
          <div className="flex items-center gap-7 flex-nowrap order-data__provider">
            <Avatar shape="circle" size={34} src={lodash.get(provider, 'logo')} className="logo" />
            <span className="text-reg text-nowrap">{`${lodash.get(record, 'providerUsername')}-${lodash.get(
              provider,
              'name'
            )} `}</span>
          </div>
        )
      },
    },
    {
      key: 'status',
      dataIndex: 'status',
      width: '20%',
      render: (status: string, record: any) => {
        const statusObj = statuses?.find(({ code }: StatusInterface) => code === status)
        const statusName = lodash.isNull(lodash.get(statusObj, 'name')) ? '---' : lodash.get(statusObj, 'name', '---')
        return (
          <div className="orders-data__status">
            <span
              style={{
                backgroundColor: lodash.isNull(lodash.get(statusObj, 'color'))
                  ? '#09B2AA'
                  : lodash.get(statusObj, 'color'),
              }}
            >
              {statusName}
            </span>
          </div>
        )
      },
    },
  ]

  const onChangePage = (page: number, pageSize: number) => {
    setPage(page)
    setPageSize(pageSize)
    handleFilter(
      getCleanFormFilter(
        {
          ...filter,
          page: (page - 1).toString(),
          size: pageSize.toString(),
        },
        [],
        ['pageSize']
      )
    )
  }

  const onChangeOrderBy = (value: string) => {
    handleFilter(
      getCleanFormFilter(
        {
          ...filter,
          orderBy: value,
          page: 0,
        },
        [],
        ['pageSize']
      )
    )
  }

  const showTotal = (total: number, range: number[]) =>
    `${range[0]}-${range[1]} ${trans('orders.inRange')} ${total} ${trans('orders.record')}`

  return (
    <div>
      <SkeletonTable columns={columns} loading={loading} className="orders-data">
        <Table
          dataSource={items}
          showHeader={false}
          rowKey={(record: any) => record.providerOrderCode}
          columns={columns}
          title={() => (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <h3 className="txt-color-black mg-t-8 mg-r-10  font-size-16 line-h-24 ">Danh Sách Đơn Hàng</h3>
                <div
                  className={`${
                    total > 0 ? 'bg-color-badge txt-color-white' : 'bg-color-gray-badge txt-color-gray4'
                  } rad-20  font-size-12 line-h-20 px-8 mg-r-20`}
                >
                  {total}
                </div>
              </div>

              <div style={{ width: 'fit-content' }}>
                <Select
                  style={{ width: 110 }}
                  className="form-input"
                  options={[
                    { value: 'asc', label: 'Cũ trước' },
                    { value: 'desc', label: 'Mới trước' },
                  ]}
                  value={orderBy ?? 'desc'}
                  onChange={onChangeOrderBy}
                />
              </div>
            </div>
          )}
          className="orders-data"
          locale={{ emptyText: <EmptyData text={trans('orders.empty')} /> }}
          scroll={{ x: 500 }}
          pagination={{
            total,
            pageSize: size,
            current: page,
            position: ['bottomRight'],
            onChange: onChangePage,
            showTotal,
            pageSizeOptions: ['10', '20', '25', '50', '100'],
            locale: { items_per_page: `/ ${trans('orders.page')}` },
            hideOnSinglePage: true,
            showSizeChanger: true,
          }}
        />
      </SkeletonTable>
    </div>
  )
}

export default List

import lodash from 'lodash'
import React from 'react'
import ProviderInterface from '../../../Interface/ProviderInterface'
import ShipmentDetailInterface from '../../../Interface/ShipmentDetailInterface'
import useWindowDimensions from '../../../Util/dimention'
import { EMPTY_INFO } from '../../../Util/Constants'
import { MobileView } from './MobileView'
import { DesktopView } from './DesktopView'

interface InformationProps {
  details?: ShipmentDetailInterface
  providers: ProviderInterface[]
  currencyOfProvider: string
  expand: boolean
  loading: boolean
}

export const textFromData = (obj: { [id: string]: any }, key: string, empty: string | Array<any>) => {
  return lodash.isNil(lodash.get(obj, key)) || lodash.isEmpty(lodash.get(obj, key)) ? empty : lodash.get(obj, key)
}

const Information: React.FC<InformationProps> = (props) => {
  const { details, expand, loading } = props
  const emptyTxt = '---'
  const refShipmentCode = textFromData(details!, 'refShipmentCode', emptyTxt)
  const refCustomerCode = textFromData(details!, 'refCustomerCode', emptyTxt)
  const originalReceipts =
    details?.originalReceipts && details.originalReceipts.length > 0 ? details.originalReceipts.join(', ') : emptyTxt
  const displayAddress = textFromData(details!, 'address.display', emptyTxt)
  const listServices = textFromData(details!, 'services', [])
  const personalNote = textFromData(details!, 'personalNote', emptyTxt)
  const employeeNote = textFromData(details!, 'employeeNote', emptyTxt)

  const chargedWeight = lodash.isNil(lodash.get(details, 'actualWeight'))
    ? emptyTxt
    : `${lodash.get(details, 'actualWeight')}kg`

  const code: any = lodash.get(details, 'code') ? lodash.get(details, 'code') : EMPTY_INFO
  const receivingWarehouseAddress = details?.receivingWarehouse?.detailWarehouse?.recipientDetail || EMPTY_INFO
  const receivingWarehouseAddressReplace = receivingWarehouseAddress.replaceAll('[[order_code]]', code)

  const { width } = useWindowDimensions()

  return width < 768 ? (
    <MobileView
      expand={expand}
      loading={loading}
      details={details}
      displayAddress={displayAddress}
      listServices={listServices}
      originalReceipts={originalReceipts}
      refShipmentCode={refShipmentCode}
      refCustomerCode={refCustomerCode}
      personalNote={personalNote}
      employeeNote={employeeNote}
      chargedWeight={chargedWeight}
    />
  ) : (
    <DesktopView
      expand={expand}
      loading={loading}
      details={details}
      displayAddress={displayAddress}
      listServices={listServices}
      originalReceipts={originalReceipts}
      refShipmentCode={refShipmentCode}
      refCustomerCode={refCustomerCode}
      personalNote={personalNote}
      employeeNote={employeeNote}
      chargedWeight={chargedWeight}
      receivingWarehouseAddressReplace={receivingWarehouseAddressReplace}
    />
  )
}

export default Information

import { notification } from 'antd'
import { ArgsProps } from 'antd/lib/notification'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { ReactNode } from 'react'

interface INotificationArgs extends Omit<ArgsProps, 'message'> {
  message: string | ReactNode
}

export class NotificationCommon {
  static success({ message = 'Thao tác thành công', description, key, ...args }: INotificationArgs) {
    notification.success({
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
      message: 'Thành công',
      description: message,
      key: key ?? String(description),
      ...args,
    })
  }

  static notifyHttpError(error: AxiosError) {
    if (error.response && error.response?.status >= 500) return
    const errorKey = error.response?.data.title
    notification.error({
      closeIcon: <i className="cursor-pointer fa-solid fa-xmark" />,
      message: 'Đã xảy ra lỗi',
      description: t('error.' + errorKey) ?? 'Lỗi không xác định. Vui lòng thử lại.',
      key: errorKey,
    })
  }
}

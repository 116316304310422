import { useState } from "react";
import lodash from "lodash";
import { Table, Tooltip } from "antd";
import { formatDateTime, formatMoneyByUnit } from "../../../Util/string";
import { trans } from "../../../resources/localization/Localization";
import TransactionInterface from "../../../Interface/TransactionInterface";
import EmptyData from "../../../Component/EmptyData";
import RowExtended from "./RowExtend";
interface TransactionProps {
  loading: boolean;
  items: TransactionInterface[];
}
function Transactions(props: TransactionProps) {
  const [expandedRows] = useState<Array<any>>([]);

  const { items } = props;
  const formatAmount = (amount: any, currency: string) => {
    let color = amount < 0 ? "text-red" : "text-green";
    return (
      <span className={color}>{`${amount > 0 ? "+" : ""}${formatMoneyByUnit(
        amount,
        currency
      )}`}</span>
    );
  };
  const columns = [
    {
      title: (
        <span className="robotoregular font-size-12 white-nowrap line-h-22 txt-color-gray">
          {trans("orderDetail.transactionTime")}
        </span>
      ),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text: string, record: any) => (
        <span className={"font-size-14 white-nowrap"}>
          {formatDateTime(record.timestamp)}
        </span>
      ),
    },

    {
      title: (
        <span className="robotoregular white-nowrap font-size-12 line-h-22 txt-color-gray">
          {trans("orderDetail.transactionAmount")}
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (text: string, record: any) => (
        <span className="font-size-14 white-nowrap">
          {formatAmount(record.amount, record.currency)}
        </span>
      ),
    },
    {
      title: (
        <span className="robotoregular font-size-12 white-nowrap line-h-22 txt-color-gray">
          {trans("orderDetail.transactionType")}
        </span>
      ),
      dataIndex: "type",
      key: "type",
      render: (text: string, record: any) => (
        <span className="font-size-14 white-nowrap">
          {lodash.get(record, "type.name", "--")}
        </span>
      ),
    },
    {
      title: (
        <span className="robotoregular font-size-12 white-nowrap line-h-22 txt-color-gray">
          {trans("orderDetail.transactionMemo")}
        </span>
      ),
      dataIndex: "memo",
      key: "memo",
      width: "50%",
      render: (text: string, record: any) => {
        const memo =
          record.memo.length > 100
            ? record.memo?.slice(0, 100) + "..."
            : record.memo;
        return (
          <div className="flex flex-col ">
            <span className="flex items-center gap-6">
              <span className="text-reg text-gray white-nowrap font-size-14 ">{`${trans(
                "order.code"
              )}:`}</span>
              <span className="text-reg text-gray white-nowrap font-size-14">
                {record.txid ? record.txid : "---"}
              </span>
            </span>
            {record.memo.length > 100 ? (
              <Tooltip title={record.memo} color="#CD5E77">
                <span className="text-reg font-size-14 white-nowrap">
                  {record.memo ? memo : "---"}
                </span>
              </Tooltip>
            ) : (
              <span className="text-reg font-size-14 white-nowrap">
                {record.memo ? memo : "---"}
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const components = { body: { row: RowExtended } };
  return (
    <Table
      columns={columns}
      dataSource={items}
      components={components}
      locale={{ emptyText: <EmptyData text={trans("orders.empty")} /> }}
      className="shipmentDetail-table"
      onRow={(record, index) => {
        const rowClassesByIdx = index! % 2 === 0 ? "row-dark" : "row-light";
        return { ...record, index, rowClassesByIdx };
      }}
      rowClassName={(record, idx) => {
        const rowClassesActiveByExpanded = lodash.includes(
          expandedRows,
          record.id
        )
          ? "active-row cursor-pointer"
          : "data-row cursor-pointer";
        const rowClassesByIdx = idx % 2 === 0 ? "row-dark " : "row-light";
        return `${rowClassesActiveByExpanded} ${rowClassesByIdx}`;
      }}
      pagination={{
        total: items.length,
        pageSize: 5,
        position: ["bottomRight"],
        locale: { items_per_page: `/ ${trans("orders.page")}` },
        hideOnSinglePage: true,
        showSizeChanger: false,
      }}
      scroll={{ x: "max-content" }}
    />
  );
}
export default Transactions;

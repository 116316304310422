import { Image, Tabs } from "antd";
import { isNull } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import EmptyData from "../../../Component/EmptyData";
import {
  PERMISSION_SHIPMENTS_PRODUCT_VIEW,
  PERMISSION_SHIPMENTS_PACKAGE_VIEW,
  PERMISSION_SHIPMENTS_TRANSACTION_VIEW,
} from "../../../Config/permission";
import OrderItemCommand from "../../../Interface/OrderItemInterface";
import PackageInterface from "../../../Interface/PackageInterface";
import ProviderInterface from "../../../Interface/ProviderInterface";
import TransactionInterface from "../../../Interface/TransactionInterface";
import imageError from "../../../resources/images/stop.png";
import {
  TAB_PACKAGES,
  TAB_PRODUCT,
  TAB_TRANSACTION,
} from "../../../Util/Constants";
import SecurityService from "../../../Util/SecurityService";
import OrderItems from "./OrderItems";
import Packages from "./Packages";
import Transactions from "./Transactions";
import { Col } from "antd";
import { ShipmentsApi } from "../../../Api/ShipmentsApi";
import ShipmentDetailInterface from "../../../Interface/ShipmentDetailInterface";

interface TabProps {
  items: OrderItemCommand[];
  confirmProductChange: (
    code: string,
    id: string,
    confirm: boolean,
    successInfo: string
  ) => void;
  providers: ProviderInterface[];
  currencyOfProvider: string;
  transactions: TransactionInterface[];
  packages: PackageInterface[];
  loadingTransactions: boolean;
  loadingPackages: boolean;
  details?: ShipmentDetailInterface;
  loading: boolean;
  updateDetail: () => void;
  fetchMileStone: () => void;
}

const TabLayout: React.FC<TabProps> = (props) => {
  let { code } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    items,
    transactions,
    loadingTransactions,
    packages,
    loadingPackages,
    details,
    loading,
  } = props;
  const [currentTabKey, setCurrentTabKey] = useState("");
  const [waybillFromShipment, setWaybillFromShipment] = useState<any>([]);
  const fetchWaybills = useCallback(async () => {
    try {
      const res = await ShipmentsApi.getWaybills(code!);
      setWaybillFromShipment(res.data);
    } catch (err) {}
  }, [code]);
  useEffect(() => {
    fetchWaybills();
  }, [fetchWaybills]);
  const handleChangeTab = (activeKey: string) => {
    setCurrentTabKey(activeKey);
    setSearchParams({ tab: activeKey });
  };

  useEffect(() => {
    const keyFromParams = isNull(searchParams.get("tab"))
      ? TAB_PRODUCT
      : searchParams.get("tab");
    setCurrentTabKey(keyFromParams!);
  }, [code, searchParams]);

  const permissionsInTab = () => {
    return (
      <Image
        src={imageError}
        alt="image-403"
        preview={false}
        width={250}
        height={250}
      />
    );
  };

  return (
    <>
      <Col span={24} className="shipment-tabs">
        <Tabs activeKey={currentTabKey} onChange={handleChangeTab}>
          {SecurityService.can(PERMISSION_SHIPMENTS_PRODUCT_VIEW) ? (
            <Tabs.TabPane
              tab={
                <span className="px-12 pd-bt-4 flex items-center">
                  <span
                    className={`${
                      currentTabKey === TAB_PRODUCT ? "" : "txt-color-gray2"
                    }  mg-r-4 pd-bt-6 text-reg text-reg-500`}
                  >
                    Sản phẩm
                  </span>
                  <span
                    className={`${
                      currentTabKey === TAB_PRODUCT
                        ? "badge badge-active"
                        : "badge"
                    }`}
                  >
                    {items.length > 0 ? (
                      <span
                        className={`${
                          currentTabKey === TAB_PRODUCT
                            ? ""
                            : " txt-color-gray2"
                        }`}
                      >
                        {items.length}
                      </span>
                    ) : (
                      <span
                        className={`${
                          currentTabKey === TAB_PRODUCT
                            ? ""
                            : " txt-color-gray2"
                        }`}
                      >
                        0
                      </span>
                    )}
                  </span>
                </span>
              }
              key={TAB_PRODUCT}
            >
              <OrderItems
                items={items}
                orderingCurrency={details?.currency}
                loading={loading}
              />
            </Tabs.TabPane>
          ) : !SecurityService.can(PERMISSION_SHIPMENTS_PRODUCT_VIEW) ? (
            <Tabs.TabPane
              tab={
                <span className="px-12 pd-bt-4 flex items-center">
                  <span className={`mg-r-4 pd-bt-6 text-reg text-reg-500`}>
                    Sản phẩm
                  </span>
                  <span
                    className={`${
                      currentTabKey === TAB_PRODUCT
                        ? "badge badge-active"
                        : "badge"
                    }`}
                  >
                    {items.length > 0 ? items.length : "0"}
                  </span>
                </span>
              }
              key={TAB_PRODUCT}
            >
              <div className="flex justify-center pd-12 width100pc">
                {permissionsInTab()}
              </div>
            </Tabs.TabPane>
          ) : (
            <Tabs.TabPane
              tab={
                <span className="px-12 pd-bt-4 flex items-center">
                  <span className={`mg-r-4 pd-bt-6 text-reg text-reg-500`}>
                    Sản phẩm
                  </span>
                  <span
                    className={`${
                      currentTabKey === TAB_PRODUCT
                        ? "badge badge-active"
                        : "badge"
                    }`}
                  >
                    {0}
                  </span>
                </span>
              }
              key={TAB_PRODUCT}
            >
              <EmptyData />{" "}
            </Tabs.TabPane>
          )}

          {SecurityService.can(PERMISSION_SHIPMENTS_PACKAGE_VIEW) ? (
            <Tabs.TabPane
              tab={
                <span className="px-12 pd-bt-4 flex items-center">
                  <span
                    className={`${
                      currentTabKey === TAB_PACKAGES ? "" : "txt-color-gray2"
                    }
									mg-r-4 pd-bt-6 text-reg text-reg-500`}
                  >
                    Vận chuyển
                  </span>
                  <span
                    className={`${
                      currentTabKey === TAB_PACKAGES
                        ? "badge badge-active"
                        : "badge"
                    }`}
                  >
                    {packages.length > 0 ? (
                      <span
                        className={`${
                          currentTabKey === TAB_PACKAGES
                            ? ""
                            : "txt-color-gray2"
                        }`}
                      >
                        {packages.length}
                      </span>
                    ) : (
                      <span
                        className={`${
                          currentTabKey === TAB_PACKAGES
                            ? ""
                            : "txt-color-gray2"
                        }`}
                      >
                        0
                      </span>
                    )}
                  </span>
                </span>
              }
              key={TAB_PACKAGES}
            >
              <Packages
                waybillFromShipment={waybillFromShipment}
                packages={packages}
                loading={loadingPackages}
                provider={details?.provider}
              />
            </Tabs.TabPane>
          ) : !SecurityService.can(PERMISSION_SHIPMENTS_PACKAGE_VIEW) &&
            searchParams.get("tab") === TAB_PACKAGES ? (
            <Tabs.TabPane
              tab={
                <span className="px-12 pd-bt-4 flex items-center">
                  <span className={`mg-r-4 pd-bt-6 text-reg text-reg-500`}>
                    Vận chuyển
                  </span>
                  <span
                    className={`${
                      currentTabKey === TAB_PACKAGES
                        ? "badge badge-active"
                        : "badge"
                    }`}
                  >
                    {packages.length > 0 ? packages.length : "0"}
                  </span>
                </span>
              }
              key={TAB_PACKAGES}
            >
              <div className="flex justify-center pd-12 width100pc">
                {permissionsInTab()}
              </div>
            </Tabs.TabPane>
          ) : (
            <Tabs.TabPane
              tab={
                <span className="px-12 pd-bt-4 flex items-center">
                  <span className={`mg-r-4 pd-bt-6 text-reg text-reg-500`}>
                    Vận chuyển
                  </span>
                  <span
                    className={`${
                      currentTabKey === TAB_PACKAGES
                        ? "badge badge-active"
                        : "badge"
                    }`}
                  >
                    {/* {transactions.length > 0 ? transactions.length : '0'} */}
                    {0}
                  </span>
                </span>
              }
              key={TAB_PACKAGES}
            >
              <EmptyData />
            </Tabs.TabPane>
          )}

          {SecurityService.can(PERMISSION_SHIPMENTS_TRANSACTION_VIEW) ? (
            <Tabs.TabPane
              tab={
                <span className="px-12 pd-bt-4 flex items-center">
                  <span
                    className={`mg-r-4 pd-bt-6 text-reg text-reg-500
									${currentTabKey === TAB_TRANSACTION ? "" : "txt-color-gray2"}`}
                  >
                    Giao dịch
                  </span>
                  <span
                    className={`${
                      currentTabKey === TAB_TRANSACTION
                        ? "badge badge-active"
                        : "badge"
                    }`}
                  >
                    {transactions.length > 0 ? (
                      <span
                        className={`${
                          currentTabKey === TAB_TRANSACTION
                            ? ""
                            : "txt-color-gray2"
                        }`}
                      >
                        {transactions.length}
                      </span>
                    ) : (
                      <span
                        className={`${
                          currentTabKey === TAB_TRANSACTION
                            ? ""
                            : "txt-color-gray2"
                        }`}
                      >
                        0
                      </span>
                    )}
                  </span>
                </span>
              }
              key={TAB_TRANSACTION}
            >
              <Transactions
                items={transactions}
                loading={loadingTransactions}
              />
            </Tabs.TabPane>
          ) : !SecurityService.can(PERMISSION_SHIPMENTS_TRANSACTION_VIEW) &&
            searchParams.get("tab") === TAB_TRANSACTION ? (
            <Tabs.TabPane
              tab={
                // <CustomerTabTitle
                // 	// label={trans('shipment.transactions')}
                // 	label='Giao dịch'
                // 	total={transactions.length}
                // 	isActive={currentTabKey === TAB_TRANSACTION}
                // 	loading={loadingTransactions}
                // />
                <span className="px-12 pd-bt-4 flex items-center">
                  <span className={`mg-r-4 pd-bt-6 text-reg text-reg-500`}>
                    Giao dịch
                  </span>
                  <span
                    className={`${
                      currentTabKey === TAB_TRANSACTION
                        ? "badge badge-active"
                        : "badge"
                    }`}
                  >
                    {transactions.length > 0 ? transactions.length : "0"}
                  </span>
                </span>
              }
              key={TAB_TRANSACTION}
            >
              <div className="flex justify-center pd-12 width100pc">
                {permissionsInTab()}
              </div>
            </Tabs.TabPane>
          ) : (
            <Tabs.TabPane
              tab={
                // <CustomerTabTitle
                // 	// label={trans('shipment.transactions')}
                // 	label='Giao dịch'
                // 	total={undefined}
                // 	isActive={currentTabKey === TAB_TRANSACTION}
                // 	loading={loadingTransactions}
                // />
                <span className="px-12 pd-bt-4 flex items-center">
                  <span className={`mg-r-4 pd-bt-6 text-reg text-reg-500`}>
                    Giao dịch
                  </span>
                  <span
                    className={`${
                      currentTabKey === TAB_TRANSACTION
                        ? "badge badge-active"
                        : "badge"
                    }`}
                  >
                    {transactions.length > 0 ? transactions.length : "0"}
                  </span>
                </span>
              }
              key={TAB_TRANSACTION}
            >
              <EmptyData />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Col>
    </>
  );
};

export default TabLayout;

/* eslint-disable array-callback-return */
import { Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import lodash from 'lodash'
import Item from './Item'
import { formatMoneyByUnit } from '../../../Util/string'
import { trans } from '../../../resources/localization/Localization'
import Skeleton from 'react-loading-skeleton'
import ShipmentDetailInterface from '../../../Interface/ShipmentDetailInterface'
import ShipmentFeeInterface from '../../../Interface/FeeInterface'
import { EstimateFee } from './EnsimateFee'
interface FeeBoxProps {
  details?: ShipmentDetailInterface
  currencyOfProvider: string
  loading: boolean
  fees: ShipmentFeeInterface[]
}
const FeeBox = (props: FeeBoxProps) => {
  const [currencyMarketplace, setCurrencyMarketplace] = useState('')
  const { details, currencyOfProvider, loading, fees } = props
  const [totalFee, setTotalFee] = useState<number | null>(0)
  const [declareValue, setDeclareValue] = useState<number | null>(0)
  const [totalRefund, setTotalRefund] = useState<number | null>(0)
  const [totalPaid, setTotalPaid] = useState<number | null>(0)
  const [totalUnpaid, setTotalUnpaid] = useState<number | null>(0)

  useEffect(() => {
    if (details) {
      setDeclareValue(lodash.get(details, 'declareValue', 0))
      setTotalPaid(lodash.get(details, 'totalPaid', 0))
      setTotalUnpaid(lodash.get(details, 'totalUnpaid', 0))
      setTotalRefund(lodash.get(details, 'totalRefund', 0))
      setCurrencyMarketplace(lodash.get(details, 'currency', ''))
      // setTotalValue(lodash.get(details, 'totalValue', 0))
    }
  }, [details])

  useEffect(() => {
    let totalFee = 0
    fees?.map((x: ShipmentFeeInterface) => {
      if (!x.free) {
        if (x.manual && x.actualAmount) {
          totalFee = totalFee + x.actualAmount
        } else if (!x.manual && x.provisionalAmount) {
          totalFee = totalFee + x.provisionalAmount
        }
      }
    })

    setTotalFee(totalFee)
    let total =
      totalFee +
      lodash.get(details, 'exchangedTotalAmount', 0)! +
      lodash.get(details, 'exchangedMerchantShippingCost', 0)!
    setTotalUnpaid(total - lodash.get(details, 'totalPaid', 0)! + lodash.get(details, 'totalRefund', 0)!)
  }, [details, fees])

  const renderFee = () => {
    return fees.map((fee: ShipmentFeeInterface, idx: number) => {
      return (
        <div className="flex justify-between fee-items mg-l-16" key={idx}>
          <span className="flex py-4 label align-items-center txt-color-gray3">
            {!loading ? fee.type?.name ? fee.type?.name : '---' : <Skeleton width={60} />}
            {!loading && <span className="label-notice txt-size-h8 txt-color-gray2 mg-l-6">{`(2.${idx + 1})`}</span>}
          </span>
          {fee.manual || fee.free ? (
            <div className="flex justify-content-end">
              <span className={' txt-color-secondary txt-linethrough mg-r-5'}>
                {fee.provisionalAmount && formatMoneyByUnit(fee.provisionalAmount)}
              </span>
              <span className={' txt-color-black'}>
                {!loading ? (
                  fee.free ? (
                    trans('miễn phí')
                  ) : (
                    formatMoneyByUnit(fee.actualAmount)
                  )
                ) : (
                  <Skeleton width={80} />
                )}
              </span>
            </div>
          ) : (
            <span>{!loading ? formatMoneyByUnit(fee.provisionalAmount) : <Skeleton width={80} />}</span>
          )}
        </div>
      )
    })
    // <></>
  }

  const estimatedFee = details?.estimatedFee
  const isShowEsimateFee = estimatedFee && estimatedFee.length > 0
  const sumOfFees = estimatedFee?.reduce((currentValue, fee) => fee.fee + currentValue, 0) ?? 0

  return (
    <div className="fees">
      <div className="fees-calculate">
        <Item
          number={'(1)'}
          label={trans('shipments.cash')}
          labelClass={'text-reg text-500'}
          valueClass="text-500"
          value={declareValue}
          currencyValue={currencyOfProvider}
          currencySubValue={currencyMarketplace}
          loading={loading}
        />
        <Item
          number={'(2)'}
          label={isShowEsimateFee ? 'Tổng phí (tạm tính)' : trans('shipments.merchantShippingCost')}
          labelClass={'text-reg text-500'}
          valueClass="text-500"
          value={isShowEsimateFee ? sumOfFees : totalFee}
          currencyValue={'currencyOfProvider'}
          currencySubValue={'currencyMarketplace'}
          loading={loading}
        />
        <Divider className="mg-0 mg-bt-4" dashed={true} />
        {isShowEsimateFee ? <EstimateFee estimatedFee={estimatedFee} currency={currencyOfProvider} /> : renderFee()}
      </div>

      {!isShowEsimateFee && (
        <div className="fees-summary">
          <Item
            number={'(3)'}
            label={trans('shipments.paid')}
            value={totalPaid}
            currencyValue={currencyOfProvider}
            loading={loading}
            labelClass={`text-500`}
            valueClass={`txt-color-green text-500`}
          ></Item>

          <Item
            number={'(4)'}
            label={trans('shipments.refund')}
            value={totalRefund}
            labelClass={`text-500`}
            currencyValue={currencyOfProvider}
            loading={loading}
            valueClass="text-500"
          ></Item>

          {details && details.status !== 'CANCELLED' ? (
            <Item
              number={'(5)'}
              valueClass={'text-reg text-500 txt-color-red'}
              labelClass={'text-reg text-500'}
              label={totalUnpaid && totalUnpaid >= 0 ? trans('shipments.needPay') : trans('shipment.excessCash')}
              value={totalUnpaid ? Math.abs(totalUnpaid) : totalUnpaid}
              currencyValue={currencyOfProvider}
              loading={loading}
            ></Item>
          ) : null}

          {details?.totalClaim && (
            <Item
              number={'(6)'}
              labelClass={'text-reg text-500'}
              valueClass="text-500"
              label={trans('shipments.totalClaim')}
              value={lodash.get(details, 'totalClaim', 0)}
              currencyValue={currencyOfProvider}
              loading={loading}
            />
          )}

          {details?.totalCollect && (
            <Item
              number={'(7)'}
              labelClass={'text-reg text-500'}
              label={trans('shipments.totalCollect')}
              value={Math.abs(lodash.get(details, 'totalCollect', 0))}
              valueClass="text-500"
              currencyValue={currencyOfProvider}
              loading={loading}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default FeeBox

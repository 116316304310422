import _ from 'lodash'
import localStore from '../../Util/LocalStore'

let language = localStore.getItem('language')

let lang = (function lang(_language) {
	switch (_language) {
		case 'en':
			return require('./locals/en/en.json')
		case 'vi':
			return require('./locals/vn/vn.json')
		default:
			return require('./locals/vn/vn.json')
	}
})(language)

const fallback = require('./locals/vn/vn.json')

export const trans = (key) => {
	let value = _.get(lang, key, key)
	if (value === key || !value) {
		return _.get(fallback, key, key)
	}

	return value
}


import { Divider } from 'antd'
import { trans } from '../../../resources/localization/Localization'
import lodash from 'lodash'
import { ServiceInterface } from '../../../Interface/OrderDetailInterface'
import Skeleton from 'react-loading-skeleton'
import { FC } from 'react'
interface IProps {
  expand: boolean
  loading: boolean
  details: any
  displayAddress: string
  listServices: any
  originalReceipts: any
  refShipmentCode: string
  refCustomerCode: string
  personalNote: string
  employeeNote: string
  chargedWeight: string
}

export const MobileView: FC<IProps> = ({
  expand,
  loading,
  details,
  displayAddress,
  listServices,
  originalReceipts,
  refShipmentCode,
  refCustomerCode,
  personalNote,
  employeeNote,
  chargedWeight,
}) => {
  return (
    <>
      <>
        <div className={`shipment-content__text-info pd-12 ${!expand ? 'py-0' : ''}`}>
          {!loading ? <h3>{trans('shipments.information')}</h3> : <Skeleton width={120} height={22} />}
          {expand && (
            <div className="flex flex-col gap-12">
              <div className="flex flex-col gap-8">
                <div>
                  <span className="txt-color-gray1 font-size-14">{trans('shipments.receiver')}:</span>
                  <span className="text-400 font-size-14 flr">{lodash.get(details, 'address.receiver', '---')}</span>
                </div>
                <div>
                  <span className="txt-color-gray1 font-size-14">{trans('shipments.phoneNumber')}:</span>
                  <span className="text-400 font-size-14 flr">{lodash.get(details, 'address.phoneNumber', '---')}</span>
                </div>

                <div className="flex">
                  <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.address')}:</div>
                  <div className="text-400 font-size-14 text-al-r">
                    {lodash.get(details, 'address.receiver', '---')},{displayAddress}
                  </div>
                </div>
              </div>
              <Divider type="horizontal" className="mg-0" />
              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.services')}:</div>
                <div className="text-400 font-size-14 text-al-r">
                  {listServices.length > 0
                    ? listServices.map((item: ServiceInterface, idx: number) => {
                        let colorService = ''
                        if (item.needApprove) {
                          if (item.approved === null || item.approved === undefined) {
                            colorService = 'services-pending'
                          } else if (item.approved === false) {
                            colorService = 'services-reject'
                          }
                        }
                        return (
                          <span key={idx} className={`mg-r-4 ${colorService} font-size-14`}>
                            {`${item.name} ${idx !== listServices.length - 1 ? '|' : ''}`}{' '}
                          </span>
                        )
                      })
                    : '---'}
                </div>{' '}
              </div>
              <Divider type="horizontal" className="mg-0" />
              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.originalInvoiceCode')}:</div>
                <div className="text-400 font-size-14 text-al-r">{originalReceipts}</div>
              </div>

              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.customerCode')}:</div>
                <div className="text-400 font-size-14 text-al-r">{refShipmentCode}</div>
              </div>
              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.customerOrderCode')}:</div>
                <div className="text-400 font-size-14 text-al-r">{refCustomerCode}</div>
              </div>
              <Divider type="horizontal" className="mg-0" />
              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.chargedWeight')}:</div>
                <div className="text-400 font-size-14 text-al-r">{chargedWeight}</div>
              </div>
              <Divider type="horizontal" className="mg-0" />
              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.employeeNote')}: </div>
                <div className="text-400 font-size-14 text-al-r">{personalNote}</div>
              </div>
              <div className="flex">
                <div className="width-50 txt-color-gray1 font-size-14">{trans('shipments.note')}: </div>
                <div className="text-400 font-size-14 text-al-r">{employeeNote}</div>
              </div>
            </div>
          )}
        </div>
      </>
    </>
  )
}

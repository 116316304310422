import React, { useEffect, useState } from 'react'
import './App.scss'
import AppRoutes from './Routes'
import { AppContext, AppContextValueInterface, defaultAppContextValue } from './Context/AppContext'
import localStore from './Util/LocalStore'
import axios from 'axios'
import appConfig from './Config/App'
import viVN from 'antd/es/locale/vi_VN'
import { ConfigProvider } from 'antd'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const App = () => {
  const [tenant, setTenant] = useState<any>()

  const getContextValue = () => {
    let value: AppContextValueInterface = { ...defaultAppContextValue }
    if (tenant) {
      value.state.tenant = tenant
    }
    return { ...value }
  }

  const fetchCurrentTenant = () => {
    axios({
      url: appConfig.apiUrl + '/tenants/current',
    })
      .then((response) => {
        setTenant(response.data)
        localStore.setJson('tenant', response.data)
      })
      .catch((err) => {
        console.log(err.response?.data)
      })
  }

  useEffect(() => {
    fetchCurrentTenant()
  }, [])

  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (e.message.includes('ResizeObserver loop')) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={viVN}>
        <AppContext.Provider value={getContextValue()}>
          <div className="main-body">
            <AppRoutes />
          </div>
        </AppContext.Provider>
      </ConfigProvider>
    </QueryClientProvider>
  )
}

export default App

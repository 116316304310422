import { Avatar, Input, Modal, Form } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import lodash from 'lodash'
import { t } from 'i18next'
import FloatLabel from '../../../Component/Floatlabel'
import { formatMoneyBySuffix } from '../../../Util/string'
import { trans } from '../../../resources/localization/Localization'

interface ModalDepositProps {
	isModalOpen: boolean
	handleOk: () => void
	handleCancel: () => void
	isLoading: boolean
	customerDetail: any
	handleSubmit: (value: any) => void
}


const ModalDeposit: React.FC<ModalDepositProps> = (props) => {
	const { customerDetail, isModalOpen, handleCancel, handleSubmit, isLoading } = props
	const [form] = Form.useForm();
	const [formEmpty, setFormEmpty] = useState(true);
	const inputRef = useRef<any>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const [formData, setFormData] = useState({
		amount: '',
		reason: '',
		note: ''
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		if (formEmpty && value !== '') {
			setFormEmpty(false);
		} else if (!formEmpty && value === '') {
			setFormEmpty(true);
		}
		if (name === 'amount') {
			let amount = value.replace(/[, . -]/g, '');
			let amountFormat = amount.replace(/[a-zA-Z]/g, '');
			let amountFormated = amountFormat.replace(/[^0-9]/g, '');

			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: amountFormated
			}));
		} else {
			setFormData((prevFormData) => ({
				...prevFormData,
				[name]: value
			}));
		}

	};

	const validateData = (body: any) => {
		if (!body.amount || !body.reason) {
			return false
		}
		return true
	}

	return (
		<>
			<Modal width={700} title={<span className='font-bold text-base'>Nạp tiền</span>} open={isModalOpen} okText={trans('Cập nhật')} cancelText={trans('Thoát')}
				okButtonProps={{ loading: isLoading, disabled: !validateData(formData), }} onOk={() => { handleSubmit(formData) }} onCancel={handleCancel}>
				<div className='flex justify-around'>
					<div className='flex flex-col items-center basis-1/3'>
						<Avatar shape='circle' size={100} src={customerDetail?.avatar ? customerDetail?.avatar : ''} className='flex-shrink-0' />
						<span className='font-medium text-sm mt-2'>{lodash.get(customerDetail, 'name', '---')}</span>
						<span className='font-medium text-sm mt-2'>{`${lodash.get(customerDetail, 'username', '---')} |
								 ${lodash.get(customerDetail, 'code', '---')}`}</span>
					</div>
					<div className='basis-2/3'>
						<Form form={form}  >

							<FloatLabel
								className='mg-bt-16 txt-color-gray1'
								label={t('deposit.amount')}
								value={formData.amount}
								required
							>
								<Input
									ref={inputRef}
									type='text'
									className='m22-input'
									placeholder=''
									allowClear
									maxLength={21}
									onChange={handleChange}
									name="amount"
									value={formData.amount ? formatMoneyBySuffix(formData.amount) : formData.amount}
									size='large'
									autoFocus
								/>

							</FloatLabel>

							<FloatLabel
								className='mg-bt-16 txt-color-gray1'
								label={t('deposit.reason')}
								value={formData.reason}
								required
							>
								<Input
									type='text'
									className='m22-input '
									placeholder=''
									allowClear
									name="reason"
									value={formData.reason}
									onChange={handleChange}
									size='large'
								/>
							</FloatLabel>

							<FloatLabel
								className='mg-bt-16 txt-color-gray1  rounded-lg'
								label={t('deposit.note')}
								value={formData.note}
							>
								<Input.TextArea

									rows={4}
									name="note"
									value={formData.note}
									onChange={handleChange}
									className='text-aria-deposit'
									placeholder=''
									allowClear
									size='large'
								/>
							</FloatLabel>
						</Form>
					</div>
				</div>
			</Modal>
		</>
	)
}

export default ModalDeposit


import lodash from 'lodash';
import moment, { Moment } from "moment";
import numeral from 'numeral';
import { STANDARD_DATE_TIME_FORMAT, STANDARD_DATE_TIME_FORMAT1 } from "./Constants";
export function formatDateTime(time: Moment | string, dateTimeFormat: string = STANDARD_DATE_TIME_FORMAT) {
  if (typeof time === 'object' && 'format' in time) {
    return time.format(dateTimeFormat);
  }
  return moment(time).format(dateTimeFormat);
}

export function formatDateTime1(time: Moment | string, dateTimeFormat: string = STANDARD_DATE_TIME_FORMAT1) {
  if (typeof time === 'object' && 'format' in time) {
    return time.format(dateTimeFormat);
  }
  return moment(time).format(dateTimeFormat);
}


export function formatNumber(value: any) {
  if(value===null||value===''||value===undefined) return '---';
  return numeral(value).format('0,0.[0000]');
}

export function formatMoneyBySuffix(value?: any, prefix: string='', suffix: string='') {
  if(value===null||value===''||value==='---'||value===undefined) return '---';
  let isNegative=false;
  if(value.toString().indexOf('-')===0){
    isNegative=true;
    value = value.toString().replace('-','');
  }
  return `${isNegative?'-':''}${prefix}${formatNumber(value)}${suffix}`;
}

export function formatMoneyByUnit(value?: any, unit?: string) {
  if(value===null||value===''||value==='---'||value===undefined) return '---';
  let suffix='';
  let prefix='';
  if(unit==='CNY')
    prefix='¥';
  else
    suffix='₫';
  return formatMoneyBySuffix(value,prefix,suffix);
}

export const getCleanFormFilter = (
	obj: any,
	keysNoCheck: string[] = [],
	keyAdded: string[] = []
) => {
	let keyOfEmptyString = [];
	if (keysNoCheck) {
		keyOfEmptyString = Object.keys(obj).filter(
			(key) =>
				lodash.isEmpty(obj[key]) &&
				lodash.isNil(obj[key]) &&
				!lodash.includes(keysNoCheck, key)
		);
	} else {
		keyOfEmptyString = Object.keys(obj).filter((key) =>
			lodash.isEmpty(obj[key])
		);
	}
	return lodash.omit(obj, [...keyOfEmptyString, ...keyAdded!]);
};



export const downloadBlobData = (data: any, fileName: any, fileType: any) => {
  if (!data) {
      return;
  }

  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.setAttribute('href', downloadUrl);
  link.setAttribute('download', `${fileName}.${fileType}`);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};


export const convertBlobDataToExcel = (data: any, fileName: string) => {
  downloadBlobData(data, fileName, 'xlsx');
};



/**
 * Supported search many item
 * @param key
 * @param value
 */
export function handleInputChangeMany(key: string, value: any, filter: any) {
	let temp = lodash.merge({}, filter);
	let realValues = lodash.get(temp, key, []);

	if (realValues.length) {
		realValues = realValues.split(',');
	}
	if (realValues.indexOf(value) >= 0) {
		realValues = lodash.filter(realValues, (n) => n !== value);
	} else {
		if (realValues === '') {
			realValues = [];
		}
		realValues.push(value);
	}
	temp[key] = realValues.join();
	if (realValues.length === 0) {
		delete temp[key];
	}
	return temp;
}

export function removeDuplicateWords(address:string) {
  const words = address.split(', '); // Tách chuỗi thành mảng các từ
  
  // Sử dụng Set để loại bỏ các từ trùng lặp
  const uniqueWords = Array.from(new Set(words));
  
  // Kết hợp các từ lại thành chuỗi
  const result = uniqueWords.join(', ');
  
  return result;
}

export function hexToRgb(hex: any, opacity: any) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgb(${r}, ${g}, ${b},${opacity})`
}